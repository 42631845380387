const server =
  window.location.hostname === "adm.hicare.net"
    ? "https://api.hicare.net"
    : window.location.hostname === "dev.adm.hicare.net"
    ? "https://dev.api.hicare.net"
    : "http://localhost:3000";
console.log(server);
export class AdminDataProvider {
  public async loginAdmin(id: any, pwd: any): Promise<any> {
    const resp = await fetch(server + "/admin/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        loginId: id,
        password: pwd,
      }),
    });

    const data = await resp.json();
    return data;
  }
  public async AddAssessment(body: any): Promise<any> {
    const resp = await fetch(server + "/admin/assessment", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
      body: JSON.stringify(body),
    });

    const data = await resp.json();
    return data;
  }
  public async GetAdminList(): Promise<any> {
    const resp = await fetch(server + "/admin/list", {
      method: "GET",
      headers: { Authorization: "Bearer " + localStorage.getItem("jwt") },
    });

    const data = await resp.json();
    return data;
  }

  public async GetMainList(): Promise<any> {
    const resp = await fetch(server + "/admin/main", {
      method: "GET",
      headers: { Authorization: "Bearer " + localStorage.getItem("jwt") },
    });

    const data = await resp.json();
    return data;
  }

  public async addDoctor(body: any): Promise<any> {
    const resp = await fetch(server + "/admin/doctor", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
      body: JSON.stringify(body),
    });

    const data = await resp.json();
    return data;
  }

  public async addAdmin(body: any): Promise<any> {
    const resp = await fetch(server + "/admin/super", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
      body: JSON.stringify(body),
    });

    const data = await resp.json();
    return data;
  }
  public async addDeviceRelation(body: any): Promise<any> {
    const resp = await fetch(server + "/admin/deviceRelation", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
      body: JSON.stringify(body),
    });

    const data = await resp.json();
    return data;
  }
  public async addDoctorRelation(body: any): Promise<any> {
    const resp = await fetch(server + "/admin/doctorRelation", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
      body: JSON.stringify(body),
    });

    const data = await resp.json();
    return data;
  }
  public async addAlert(body: any): Promise<any> {
    const resp = await fetch(server + "/admin/alert", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
      body: JSON.stringify(body),
    });

    const data = await resp.json();
    return data;
  }
  public async addWebPage(body: any): Promise<any> {
    const resp = await fetch(server + "/admin/alert", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
      body: JSON.stringify(body),
    });

    const data = await resp.json();
    return data;
  }
  public async DeleteDoctor(seq: any): Promise<any> {
    const resp = await fetch(server + "/admin/doctor/" + seq, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
    });

    const data = await resp.json();
    return data;
  }
  public async DeleteAdmin(seq: any): Promise<any> {
    const resp = await fetch(server + "/admin/" + seq, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
    });

    const data = await resp.json();
    return data;
  }
  public async addPatient(body: any): Promise<any> {
    const resp = await fetch(server + "/admin/patient", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
      body: JSON.stringify(body),
    });

    const data = await resp.json();
    return data;
  }
  public async DeletePatient(seq: any): Promise<any> {
    const resp = await fetch(server + "/admin/patient/" + seq, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
    });

    const data = await resp.json();
    return data;
  }
  public async DeleteDeviceRelations(seq: any): Promise<any> {
    const resp = await fetch(server + "/admin/deviceRelations/" + seq, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
    });

    const data = await resp.json();
    return data;
  }
  public async DeleteDoctorPatientRelation(seq: any): Promise<any> {
    const resp = await fetch(server + "/admin/doctorPatientRelation/" + seq, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
    });

    const data = await resp.json();
    return data;
  }
  public async DeleteDevice(seq: any): Promise<any> {
    const resp = await fetch(server + "/admin/device/" + seq, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
    });

    const data = await resp.json();
    return data;
  }
}
