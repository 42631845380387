/* react */
import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";

import { IMedicalDetailProps } from "./IMedicalDetail";
import "jquery-ui";
import { Common } from "../../lib/utils/common";
import { useHistory, useParams } from "react-router-dom";

import {
  Button,
  DateBox,
  LoadPanel,
  Popup,
  RadioGroup,
  SelectBox,
  TextArea,
  TextBox,
  Validator,
} from "devextreme-react";

import * as _ from "lodash";
import $ from "jquery";
import { AdminDataProvider } from "../../lib/service/AdminDataProvider";
import tBox from "devextreme/ui/text_box";
import dBox from "devextreme/ui/date_box";
import rGroup from "devextreme/ui/radio_group";
import { alert, confirm } from "devextreme/ui/dialog";
import DataGrid, {
  Column,
  Pager,
  Paging,
  RequiredRule,
} from "devextreme-react/data-grid";
import moment from "moment";
import { lang } from "../../lib/utils/language";
import { locale, loadMessages, formatMessage } from "devextreme/localization";
loadMessages(lang);
locale("" + localStorage.getItem("lang"));
export const MedicalDetailProps: FunctionComponent<IMedicalDetailProps> = (
  props
) => {
  const history = useHistory();
  const common = new Common();
  const param: any = useParams();
  const [doctor, setDoctor] = useState([] as any);
  const [patient, setPatient] = useState([] as any);
  const [doctorPatientRelation, setDoctorPatientRelation] = useState([] as any);
  const [passReset, setPassReset] = useState(false);
  /* componentDidMount && componentDidUpdate */
  useEffect(() => {
    common.ResizePage();
    const fetchArticles = async () => {
      const adminService = new AdminDataProvider();
      const main = await adminService.GetMainList();

      setDoctor(
        _.filter(main.data.doctor, { doctorSeq: parseInt(param.id) })[0]
      );
      const doc = _.map(
        _.filter(main.data.doctorPatientRelation, {
          doctorSeq: parseInt(param.id),
        }),
        "insungSeq"
      );
      setDoctorPatientRelation(main.data.doctorPatientRelation);
      setPatient(
        _.filter(main.data.patient, function (o) {
          return doc.includes(o.insungSeq);
        })
      );
      const a = _.filter(main.data.doctor, {
        doctorSeq: parseInt(param.id),
      })[0];
      _dev("txtFirstName", tBox).option("value", a.firstName);
      _dev("txtMidName", tBox).option("value", a.middleName);
      _dev("txtLastName", tBox).option("value", a.lastName);
      _dev("txtId", tBox).option("value", a.loginId);
    };

    if (localStorage.getItem("id") === "system") {
      history.push("/superAdmin");
    } else if (localStorage.getItem("jwt") !== null) {
      fetchArticles();
    } else {
      history.push("/auth/login");
    }
  }, [props]);

  const _dev = (e: any, d: any) => {
    const id = document.getElementById(e) as HTMLElement;
    return d.getInstance(id);
  };

  const btnSave = async (d: any) => {
    const adminService = new AdminDataProvider();

    const txtFirstName = _dev("txtFirstName", tBox).option("value");
    const txtMidName = _dev("txtMidName", tBox).option("value");
    const txtLastName = _dev("txtLastName", tBox).option("value");
    const txtId = _dev("txtId", tBox).option("value");

    const json = {
      doctorSeq: param.id,
      txtFirstName: txtFirstName,
      txtMidName: txtMidName,
      txtLastName: txtLastName,
      txtId: txtId,
      passReset: passReset,
    };

    if (d.validationGroup.validate().isValid) {
      let result = confirm(
        "<i>" + formatMessage("msg_edit_ms") + "</i>",
        "Confirm changes"
      );
      result.then((dialogResult) => {
        if (dialogResult) {
          adminService.addDoctor(json).then((e: any) => {
            alert(formatMessage("msg_changed"), "Save");
            setPassReset(false);
            history.push("/medicalInfo/" + param.id);
          });
        } else {
          alert(formatMessage("msg_cancel"), "Canceld");
        }
      });
    } else {
      alert(formatMessage("msg_error_occurred"), "Warning");
    }
  };
  const btnDeleteDoctor = async (d: any) => {
    const adminService = new AdminDataProvider();
    let result = confirm(
      "<i>" + formatMessage("msg_delete_ms") + "</i>",
      "Confirm changes"
    );
    result.then((dialogResult) => {
      if (dialogResult) {
        alert(formatMessage("msg_deleted"), "Delete");
        adminService.DeleteDoctor(param.id).then((e: any) => {
          history.push("/medical");
        });
      } else {
        alert("Canceled", "Canceled");
      }
    });
  };
  const btnDeleteDoctorPatientRelation = async (d: any) => {
    const adminService = new AdminDataProvider();
    alert(formatMessage("msg_deleted"), "Delete");
    adminService.DeleteDoctorPatientRelation(d).then((e: any) => {
      history.push("/medicalInfo/" + param.id);
    });
  };
  return (
    <main id="content" className="content">
      <section className="sub-top">
        <header>
          <div className="page-history">
            <ul>
              <li>
                <a href="/main">
                  <i className="fa fa-home" aria-hidden="true"></i>
                  <span className="blind">home</span>
                </a>
              </li>
              <li>
                <a href="/medical">
                  <span>Medical Staff</span>
                </a>
              </li>
              <li className="current">
                <a href={window.location.href}>
                  <span>Medical Staff Info.</span>
                </a>
              </li>
            </ul>
          </div>
          <h2>Medical Staff Info.</h2>
          <article className="info-wrap">
            <header>
              <span className="badge lg blue-bg">Dr</span>
              <div className="text-wrap">
                <p>
                  <strong>{doctor.name}</strong>
                  <span>({doctor.loginId})</span>
                </p>
              </div>
            </header>
          </article>
        </header>

        <article className="reg-form info">
          <form action="" name="regForm">
            <fieldset>
              <legend className="blind">Medical Staff Info.</legend>
              <article>
                <header>
                  <span>NAME</span>
                  <span className="req"></span>
                </header>
                <div className="cont">
                  <TextBox
                    id="txtFirstName"
                    placeholder="FirstName"
                    className="inp"
                  >
                    <Validator>
                      <RequiredRule message="FirstName is required" />
                    </Validator>
                  </TextBox>
                  {/* <div id="midName" className="inp"></div> */}
                  <TextBox
                    id="txtMidName"
                    placeholder="MidName"
                    className="inp"
                  >
                    {/* <Validator>
                      <RequiredRule message="MidName is required" />
                    </Validator> */}
                  </TextBox>
                  {/* <div id="lastName" className="inp"></div> */}
                  <TextBox
                    id="txtLastName"
                    placeholder="LastName"
                    className="inp"
                  >
                    <Validator>
                      <RequiredRule message="LastName is required" />
                    </Validator>
                  </TextBox>
                </div>
              </article>
              <article>
                <header>
                  <span>ID</span>
                  <span className="req"></span>
                </header>
                <div className="cont">
                  <TextBox id="txtId" placeholder="ID" className="inp">
                    <Validator>
                      <RequiredRule message="ID is required" />
                    </Validator>
                  </TextBox>
                </div>
              </article>
              <article>
                <header>
                  <span>PASSWORD</span>
                  <span className="req"></span>
                </header>
                <div className="cont">
                  <span>****</span>
                  <Button
                    className="btn md ml10"
                    id="pwReset"
                    text="Reset"
                    type="default"
                    stylingMode="contained"
                    // useSubmitBehavior={true}
                    onClick={(e: any) => {
                      alert(formatMessage("msg_password_changed_1234"), "Save");
                      setPassReset(true);
                    }}
                  />
                </div>
              </article>
              <article>
                <header>
                  <span>REGISTER DATE</span>
                </header>
                <div className="cont">
                  <span>{moment(doctor.inputUtcDt).format("DD/MM/YYYY")}</span>
                </div>
              </article>
              <article>
                <header>
                  <span>Last UPDATE</span>
                </header>
                <div className="cont">
                  <span>{moment(doctor.inputUtcDt).format("DD/MM/YYYY")}</span>
                </div>
              </article>
              <footer>
                <Button
                  className="btn md flex-mla"
                  id="btnSave"
                  text="SAVE"
                  type="success"
                  icon="fa fa-check"
                  stylingMode="contained"
                  // useSubmitBehavior={true}
                  onClick={(e: any) => {
                    btnSave(e);
                  }}
                />
                <Button
                  className="btn md flex-mla ml10"
                  id="btnDelete"
                  text="DELETE"
                  type="success"
                  icon="fa fa-trash-o"
                  stylingMode="contained"
                  // useSubmitBehavior={true}
                  onClick={btnDeleteDoctor}
                />
              </footer>
            </fieldset>
          </form>
        </article>
      </section>

      <section className="sub-cont">
        <header>
          <h2>
            <span>Linked Info.</span>
            <span className="num-ea">{patient.length}</span>
          </h2>
        </header>
        <DataGrid
          id="gridCont1"
          dataSource={patient}
          hoverStateEnabled={true}
          className="type-ct"
          style={{ minWidth: 1000 }}
        >
          <Column
            caption="No"
            width={100}
            cellRender={(e) => {
              return e.row.rowIndex + 1;
            }}
          />
          <Column
            dataField="name"
            caption="PATIENT NAME"
            cellTemplate={(container: any, options: any) => {
              $("<a class='link'>")
                .on("click", () => {
                  history.push("/patientInfo/" + options.data.insungSeq);
                })
                .append(options.value)
                .appendTo(container);
            }}
          />
          <Column dataField="gender" caption="GENDER" />
          <Column
            dataField="SERVICE"
            caption="SERVICE"
            cellTemplate={(container: any, options: any) => {
              $("<span class='icon'>")
                .append($("<i>", { class: "fa fa-bar-chart" }))
                .appendTo(container)
                .append("<span>VideoCall</span></br>")
                .appendTo(container)
                .append($("<i>", { class: "fa fa-video-camera" }))
                .appendTo(container)
                .append("<span>WebChart</span>")
                .appendTo(container);
            }}
          />
          <Column
            dataField="inputUtcDt"
            caption="LINKED DATE"
            dataType="date"
            format="yyyy-MM-dd"
          />
          <Column
            dataField="STATUS"
            caption="STATUS"
            cellTemplate={(container: any, options: any) => {
              $("<span class='link' style='cursor:pointer'>")
                .on("click", () => {
                  const seq = _.filter(doctorPatientRelation, {
                    insungSeq: options.data.insungSeq,
                    doctorSeq: param.id,
                  })[0].seq;
                  btnDeleteDoctorPatientRelation(seq);
                })
                .append("<i class='fa fa-times' aria-hidden='true'>UNLINK</i>")
                .appendTo(container);
            }}
          />
          <Paging defaultPageSize={10} />
          <Pager
            visible={true}
            allowedPageSizes={[10, 20, "all"]}
            displayMode={"full"}
            showPageSizeSelector={true}
            showInfo={true}
            showNavigationButtons={true}
          />
        </DataGrid>
      </section>
    </main>
  );
};

export default MedicalDetailProps;
