/* react */
import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";

import { IPatientProps } from "./IPatient";
import "jquery-ui";
import { Common } from "../../lib/utils/common";
import { useHistory } from "react-router-dom";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import "devextreme/dist/css/dx.generic.custom-hicareAdmin.css";
import "../../css/font-awesome.min.css";
import "../../css/layout.css";
import "../../css/custom.css";
import "jquery-ui/themes/base/jquery-ui.css";
import {
  Button,
  DateBox,
  RadioGroup,
  TextBox,
  Validator,
} from "devextreme-react";
import * as _ from "lodash";
import DataGrid, {
  Column,
  Pager,
  Paging,
  RequiredRule,
  SearchPanel,
  LoadPanel,
} from "devextreme-react/data-grid";
import $ from "jquery";
import { AdminDataProvider } from "../../lib/service/AdminDataProvider";
import tBox from "devextreme/ui/text_box";
import dBox from "devextreme/ui/date_box";
import rGroup from "devextreme/ui/radio_group";
import { alert } from "devextreme/ui/dialog";
import { lang } from "../../lib/utils/language";
import { locale, loadMessages, formatMessage } from "devextreme/localization";
loadMessages(lang);
locale("" + localStorage.getItem("lang"));
export const Patient: FunctionComponent<IPatientProps> = (props) => {
  const history = useHistory();
  const common = new Common();
  const [patient, setPatient] = useState([] as any);
  const [duplicate, setDuplicate] = useState(true);
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  /* componentDidMount && componentDidUpdate */
  useEffect(() => {
    common.ResizePage();
    const fetchArticles = async () => {
      const adminService = new AdminDataProvider();

      const main = await adminService.GetMainList();

      setStartDate(new Date());
      setPatient(main.data.patient);
    };
    if (localStorage.getItem("id") === "system") {
      history.push("/superAdmin");
    } else if (localStorage.getItem("jwt") !== null) {
      fetchArticles();
    } else {
      history.push("/auth/login");
    }
  }, [props]);

  const sex = ["Female", "Male"];

  const _dev = (e: any, d: any) => {
    const id = document.getElementById(e) as HTMLElement;
    return d.getInstance(id);
  };
  const btnChk = async (d: any) => {
    const loginId = _dev("txtId", tBox).option("value");
    const chk = _.filter(patient, {
      loginId: loginId,
    });

    if (chk.length > 0) {
      alert(formatMessage("msg_check_duplication"), "Warning");
      setDuplicate(true);
    } else if (loginId.length === 0) {
      alert(formatMessage("msg_input_id"), "Warning");
      setDuplicate(true);
    } else {
      alert(formatMessage("msg_matched"), "Warning");
      setDuplicate(false);
    }
  };

  const btnSave = async (d: any) => {
    const adminService = new AdminDataProvider();
    const txtFirstName = _dev("txtFirstName", tBox).option("value");
    const txtMidName = _dev("txtMidName", tBox).option("value");
    const txtLastName = _dev("txtLastName", tBox).option("value");
    const txtId = _dev("txtId", tBox).option("value");
    const txtPID = _dev("txtPID", tBox).option("value");
    const txtPW = _dev("txtPW", tBox).option("value");
    const txtPWC = _dev("txtPWC", tBox).option("value");
    const dateBirth = _dev("dateBirth", dBox).option("value");
    const rdgSex = _dev("rdgSex", rGroup).option("value");

    const json = {
      txtFirstName: txtFirstName,
      txtMidName: txtMidName,
      txtLastName: txtLastName,
      txtId: txtId,
      txtPID: txtPID,
      txtPW: txtPW,
      txtPWC: txtPWC,
      dateBirth: dateBirth,
      rdgSex: rdgSex.charAt(0),
    };

    if (d.validationGroup.validate().isValid) {
      if (duplicate) {
        alert(formatMessage("msg_check_duplication"), "Warning");
      } else if (txtPW !== txtPWC) {
        alert(formatMessage("msg_check_duplication"), "Warning");
      } else {
        adminService.addPatient(json).then(async (e: any) => {
          alert(formatMessage("msg_created"), "Save");
          d.validationGroup.reset();
          history.push("./patient");
        });
      }
    } else {
      alert(formatMessage("msg_error_occurred"), "Warning");
    }
  };
  return (
    <main id="content" className="content">
      <section className="sub-top">
        <header>
          <div className="page-history">
            <ul>
              <li>
                <a href="/main">
                  <i className="fa fa-home" aria-hidden="true"></i>
                </a>
              </li>
              <li className="current">
                <a href="/patient">
                  <span>Patient</span>
                </a>
              </li>
            </ul>
          </div>
          <h2>Patient Registration</h2>
        </header>

        <article className="reg-form insert">
          <form action="" name="regForm">
            <fieldset>
              <legend className="blind">Registration</legend>
              <article>
                <header>
                  <i className="fa fa-tasks" aria-hidden="true"></i>
                  <span>NAME</span>
                  <span className="req"></span>
                </header>
                <div className="cont">
                  {/* <div id="firstName" className="inp"></div> */}
                  <TextBox
                    id="txtFirstName"
                    placeholder="FirstName"
                    className="inp"
                  >
                    <Validator>
                      <RequiredRule message="FirstName is required" />
                    </Validator>
                  </TextBox>
                  {/* <div id="midName" className="inp"></div> */}
                  <TextBox
                    id="txtMidName"
                    placeholder="MidName"
                    className="inp"
                  ></TextBox>
                  {/* <div id="lastName" className="inp"></div> */}
                  <TextBox
                    id="txtLastName"
                    placeholder="LastName"
                    className="inp"
                  >
                    <Validator>
                      <RequiredRule message="LastName is required" />
                    </Validator>
                  </TextBox>
                </div>
              </article>
              <article>
                <header>
                  <i className="fa fa-tasks" aria-hidden="true"></i>
                  <span>ID</span>
                  <span className="req"></span>
                </header>
                <div className="cont">
                  <TextBox id="txtId" placeholder="ID" className="inp">
                    <Validator>
                      <RequiredRule message="ID is required" />
                    </Validator>
                  </TextBox>
                  <Button
                    className="btn md ml10"
                    id="btnChk"
                    text="ID Duplication Check"
                    type="default"
                    stylingMode="contained"
                    onClick={btnChk}
                  />
                </div>
              </article>
              <article>
                <header>
                  <i className="fa fa-tasks" aria-hidden="true"></i>
                  <span>PID(LINK ID)</span>
                </header>
                <div className="cont">
                  <TextBox id="txtPID" placeholder="PID" className="inp" />
                </div>
              </article>
              <article>
                <header>
                  <i className="fa fa-tasks" aria-hidden="true"></i>
                  <span>PASSWORD</span>
                  <span className="req"></span>
                </header>
                <div className="cont">
                  <TextBox
                    id="txtPW"
                    placeholder="Password"
                    className="inp"
                    mode="password"
                  >
                    <Validator>
                      <RequiredRule message="Password is required" />
                    </Validator>
                  </TextBox>
                  <TextBox
                    id="txtPWC"
                    mode="password"
                    placeholder="Password Confirmed"
                    className="inp"
                  >
                    <Validator>
                      <RequiredRule message="Password Confirmed is required" />
                    </Validator>
                  </TextBox>
                </div>
              </article>
              <article>
                <header>
                  <i className="fa fa-tasks" aria-hidden="true"></i>
                  <span>BIRTH</span>
                  <span className="req"></span>
                </header>
                <div className="cont">
                  <DateBox
                    id="dateBirth"
                    value={startDate}
                    displayFormat="yyyy-MM-dd"
                    type="date"
                  />
                </div>
              </article>
              <article>
                <header>
                  <i className="fa fa-tasks" aria-hidden="true"></i>
                  <span>GENDER</span>
                  <span className="req"></span>
                </header>
                <div className="cont">
                  <RadioGroup
                    id="rdgSex"
                    items={sex}
                    defaultValue={sex[0]}
                    layout="horizontal"
                    className="ans"
                  />
                </div>
              </article>
              <div className="flex-wrap">
                <Button
                  className="btn mlg flex-mla"
                  id="btnSave"
                  text="Register New"
                  type="success"
                  stylingMode="contained"
                  // useSubmitBehavior={true}
                  onClick={(e: any) => {
                    btnSave(e);
                  }}
                />
              </div>
            </fieldset>
          </form>
        </article>
      </section>

      <section className="sub-cont search-mode">
        <header>
          <h2>Patient List</h2>
          <div className="header-item">
            <ul className="icon-view">
              <li>
                <i className="fa fa-video-camera" aria-hidden="true"></i>
                <span>Videocall</span>
              </li>
              <li>
                <i className="fa fa-bar-chart" aria-hidden="true"></i>
                <span>WebChart</span>
              </li>
            </ul>
            <div id="a" className="search-wrap"></div>
          </div>
        </header>

        <section className="sub-cont__wrap">
          <article>
            <div className="tbl-wrap">
              <DataGrid
                id="gridCont1"
                dataSource={patient}
                columnHidingEnabled={true}
                noDataText="No data"
                selection={{ mode: "single" }}
                hoverStateEnabled={true}
                className="type-ct"
                style={{ minWidth: 1000 }}
              >
                <LoadPanel enabled />
                <SearchPanel visible={true} highlightCaseSensitive={true} />
                <Column
                  caption="No"
                  width={100}
                  cellRender={(e) => {
                    return e.row.rowIndex + 1;
                  }}
                />
                <Column
                  dataField="name"
                  caption="NAME"
                  cellTemplate={(container: any, options: any) => {
                    $(
                      "<a href='" +
                        "/patientInfo/" +
                        options.data.insungSeq +
                        "' class='link'>"
                    )
                      .append(options.value)
                      .appendTo(container);
                  }}
                />
                <Column dataField="gender" caption="GENDER" />
                <Column
                  dataField="linkDevice"
                  caption="LINKED DEVICE"
                  cellTemplate={(container: any, options: any) => {
                    $("<span class='icon'>")
                      .append($("<i>", { class: "fa fa-laptop" }))
                      .appendTo(container)
                      .append("<span> " + options.value + " </span>")
                      .appendTo(container);
                  }}
                />
                <Column
                  dataField="linkDoctor"
                  caption="LINKED DOCTOR"
                  cellTemplate={(container: any, options: any) => {
                    $("<span class='icon'>")
                      .append($("<i>", { class: "fa fa-bar-chart" }))
                      .appendTo(container)
                      .append("<span> " + options.value + " </span>")
                      .appendTo(container)
                      .append($("<i>", { class: "fa fa-video-camera" }))
                      .appendTo(container)
                      .append("<span> " + options.value + " </span>")
                      .appendTo(container);
                  }}
                />
                <Column
                  dataField="inputUtcDt"
                  caption="REGISTER DATE"
                  dataType="date"
                  format="yyyy-MM-dd hh:mm:ss"
                />

                <Paging defaultPageSize={10} />
                <Pager
                  visible={true}
                  allowedPageSizes={[10, 20, "all"]}
                  displayMode={"full"}
                  showPageSizeSelector={true}
                  showInfo={true}
                  showNavigationButtons={true}
                />
              </DataGrid>
            </div>
          </article>
        </section>
      </section>
    </main>
  );
};

export default Patient;
