/* react */
import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";

import { IDeviceDetailProps } from "./IDeviceDetail";
import "jquery-ui";
import { Common } from "../../lib/utils/common";
import { useHistory, useParams } from "react-router-dom";

import {
  Button,
  DataGrid,
  DateBox,
  LoadPanel,
  Popup,
  RadioGroup,
  SelectBox,
  TextArea,
  TextBox,
} from "devextreme-react";
import * as _ from "lodash";
import { AdminDataProvider } from "../../lib/service/AdminDataProvider";
import moment from "moment";
import { Column, Pager, Paging, SearchPanel } from "devextreme-react/data-grid";
import $ from "jquery";
export const DeviceDetail: FunctionComponent<IDeviceDetailProps> = (props) => {
  const history = useHistory();
  const common = new Common();
  const param: any = useParams();
  const [deviceDetail, setDeviceDetail] = useState([] as any);
  const [deviceRelations, setDeviceRelations] = useState([] as any);
  const [patient, setPatient] = useState([] as any);
  /* componentDidMount && componentDidUpdate */
  useEffect(() => {
    common.ResizePage();
    const fetchArticles = async () => {
      const adminService = new AdminDataProvider();
      const main = await adminService.GetMainList();
      const dr = _.map(
        _.filter(main.data.deviceRelations, { deviceId: param.id }),
        "insungSeq"
      );
      console.log(main.data.devices);
      setDeviceRelations(main.data.deviceRelations);
      setDeviceDetail(
        _.filter(main.data.devices, { devices_device_id: param.id })[0]
      );

      setPatient(
        _.filter(main.data.patient, function (o) {
          return dr.includes(o.insungSeq);
        })
      );
    };

    if (localStorage.getItem("id") === "system") {
      history.push("/superAdmin");
    } else if (localStorage.getItem("jwt") !== null) {
      fetchArticles();
    } else {
      history.push("/auth/login");
    }
  }, [props]);
  const btnDeleteDeviceRelations = async (d: any) => {
    const adminService = new AdminDataProvider();
    adminService.DeleteDeviceRelations(d).then((e: any) => {
      history.push("/deviceInfo/" + param.id);
    });
  };
  const btnDeleteDevice = async (d: any) => {
    const adminService = new AdminDataProvider();
    adminService.DeleteDevice(deviceDetail.devices_seq).then((e: any) => {
      history.push("/device");
    });
  };
  return (
    <main id="content" className="content">
      <section className="sub-top">
        <header>
          <div className="page-history">
            <ul>
              <li>
                <a href="/main">
                  <i className="fa fa-home" aria-hidden="true"></i>
                  <span className="blind">home</span>
                </a>
              </li>
              <li>
                <a href="/device">
                  <span>Device</span>
                </a>
              </li>
              <li className="current">
                <a href={window.location.href}>
                  <span>Device Info.</span>
                </a>
              </li>
            </ul>
          </div>
          <h2>Device Info.</h2>
          <article className="info-wrap">
            <header>
              <img
                src="../images/glyphicons/glyphicons_162_hx461.png"
                alt="images info text"
              />
              <div className="text-wrap">
                <p>
                  <strong>{param.id}</strong>
                  <span>({deviceDetail.devices_device_type})</span>
                </p>
                <p>
                  REGISTER DATE :
                  {moment(deviceDetail.devices_input_dt).format("YYYY-MM-DD")}
                </p>
              </div>
            </header>
            <footer>
              <Button
                className="btn md flex-mla ml10"
                id="btnDelete"
                text="DELETE"
                type="success"
                icon="fa fa-trash-o"
                stylingMode="contained"
                // useSubmitBehavior={true}
                onClick={btnDeleteDevice}
              />
            </footer>
          </article>
        </header>
      </section>

      <section className="sub-cont">
        <header>
          <h2>
            <span>Linked Info.</span>
            <span className="num-ea">{patient.length}</span>
          </h2>
        </header>
        <section className="sub-cont__wrap">
          <article>
            <div className="tbl-wrap">
              <DataGrid
                id="gridCont1"
                dataSource={patient}
                hoverStateEnabled={true}
                className="type-ct"
                style={{ minWidth: 1000 }}
              >
                <SearchPanel visible={true} highlightCaseSensitive={true} />
                <Column
                  caption="No"
                  width={100}
                  cellRender={(e) => {
                    return e.row.rowIndex + 1;
                  }}
                />
                <Column
                  dataField="name"
                  caption="PATIENT NAME"
                  cellTemplate={(container: any, options: any) => {
                    $("<a class='link'>")
                      .on("click", () => {
                        history.push("/patientInfo/" + options.data.insungSeq);
                      })
                      .append(options.value)
                      .appendTo(container);
                  }}
                />
                <Column dataField="gender" caption="GENDER" />
                <Column dataField="inputUtcDt" caption="LINKED DATE" />
                <Column
                  dataField="STATUS"
                  caption="STATUS"
                  cellTemplate={(container: any, options: any) => {
                    $("<span class='link' style='cursor:pointer'>")
                      .on("click", () => {
                        const seq = _.filter(deviceRelations, {
                          insungSeq: options.data.insungSeq,
                          deviceId: param.id,
                        })[0].seq;
                        btnDeleteDeviceRelations(seq);
                      })
                      .append(
                        "<i class='fa fa-times' aria-hidden='true'>UNLINK</i>"
                      )
                      .appendTo(container);
                  }}
                />
                <Paging defaultPageSize={10} />
                <Pager
                  visible={true}
                  allowedPageSizes={[10, 20, "all"]}
                  displayMode={"full"}
                  showPageSizeSelector={true}
                  showInfo={true}
                  showNavigationButtons={true}
                />
              </DataGrid>
            </div>
          </article>
        </section>
      </section>
    </main>
  );
};

export default DeviceDetail;
