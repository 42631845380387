/* react */
import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";

import { IMeasurementProps } from "./IMeasurement";
import "jquery-ui";
import { Common } from "../../lib/utils/common";
import { useHistory } from "react-router-dom";

import {
  Button,
  DataGrid,
  DateBox,
  LoadPanel,
  Popup,
  RadioGroup,
  SelectBox,
  TextArea,
  TextBox,
} from "devextreme-react";
import * as _ from "lodash";
import { Column, Pager, Paging, SearchPanel } from "devextreme-react/data-grid";
import { AdminDataProvider } from "../../lib/service/AdminDataProvider";
import $ from "jquery";
export const Measurement: FunctionComponent<IMeasurementProps> = (props) => {
  const history = useHistory();
  const common = new Common();
  const [measurement, setMeasurement] = useState([] as any);
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [gridFilterValue, setGridFilterValue] = useState<any>([]);
  /* componentDidMount && componentDidUpdate */
  useEffect(() => {
    const fetchArticles = async () => {
      common.ResizePage();
      const adminService = new AdminDataProvider();
      const main = await adminService.GetMainList();
      setMeasurement(main.data.measurement);
    };

    if (localStorage.getItem("id") === "system") {
      history.push("/superAdmin");
    } else if (localStorage.getItem("jwt") !== null) {
      fetchArticles();
    } else {
      history.push("/auth/login");
    }
  }, [props]);
  const onSearch = async () => {
    let filter = [["measureUtcDt", "between", [startDate, endDate]]];
    if (startDate !== undefined && endDate !== undefined) {
      console.log(filter);
      setGridFilterValue(filter);
    }
  };
  const onSDateChanged = async (e: any) => {
    setStartDate(e.value);
  };
  const onEDateChanged = async (e: any) => {
    setEndDate(e.value);
  };
  return (
    <main id="content" className="content">
      <section className="sub-top">
        <header>
          <div className="page-history">
            <ul>
              <li>
                <a href="#">
                  <i className="fa fa-home" aria-hidden="true"></i>
                  <span className="blind">home</span>
                </a>
              </li>
              <li className="current">
                <a href="#">
                  <span>Measurement History</span>
                </a>
              </li>
            </ul>
          </div>
          <h2>Measurement History</h2>
        </header>
      </section>

      <section className="sub-cont">
        <header>
          <div className="search-wrap">
            <div className="wrapper">
              <DateBox
                id="fromDate"
                value={startDate}
                max={endDate}
                displayFormat="yyyy-MM-dd"
                type="date"
                onValueChanged={onSDateChanged}
              />
              <span className="hyphen"> ~ </span>
              <DateBox
                id="toDate"
                value={endDate}
                displayFormat="yyyy-MM-dd"
                type="date"
                onValueChanged={onEDateChanged}
              />
            </div>
            <Button
              className="btn md blue ml10"
              id="btnSearch"
              text="Search"
              type="success"
              stylingMode="contained"
              icon="fa fa-search"
              onClick={onSearch}
            />
          </div>
        </header>

        <section className="sub-cont__wrap">
          <article>
            <div className="tbl-wrap">
              {" "}
              <DataGrid
                id="gridCont1"
                dataSource={measurement}
                hoverStateEnabled={true}
                filterValue={gridFilterValue}
                className="type-ct"
                style={{ minWidth: 1000 }}
              >
                <Column
                  caption="No"
                  width={50}
                  cellRender={(e) => {
                    return e.row.rowIndex + 1;
                  }}
                />
                <Column dataField="name" caption="PATIENT NAME" />
                <Column
                  dataField="measureUtcDt"
                  caption="REQUEST DATE"
                  dataType="date"
                  format="yyyy-MM-dd"
                />
                <Column dataField="measureType" caption="MEASURE TYPE" />
                <Column dataField="autotTimezone" caption="AUTO TIMEZONE" />

                <Paging defaultPageSize={10} />
                <Pager
                  visible={true}
                  allowedPageSizes={[10, 20, "all"]}
                  displayMode={"full"}
                  showPageSizeSelector={true}
                  showInfo={true}
                  showNavigationButtons={true}
                />
              </DataGrid>
            </div>
          </article>
        </section>
      </section>
    </main>
  );
};

export default Measurement;
