import OpenTok from "opentok";

export class HLOListDataProvider {
  public async GetHicareLicence(): Promise<any> {
    const resp = await fetch("http://localhost:3000/licence/list", {
      method: "GET",
    });

    const data = await resp.json();
    return data;
  }

  public async addHicareLicence(licence: any): Promise<any> {
    const resp = await fetch("http://localhost:3000/licence/list", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(licence),
    });

    const data = await resp.json();
    return data;
  }
}
