import $ from "jquery";

export class Common {
  public ResizePage(): void {
    var $select = $("select");
    var $menuAll = $(".menu-all");
    var $nav = $("nav");
    var $popUp = $(".pop-layer");
    var $popWrap = $(".pop-layer__wrap");
    var $btn = $(".btn");
    var speed = 500;

    // select focus
    $select.on("focus", function () {
      $(this).parents(".opt").addClass("focus");
    });
    $select.on("blur", function () {
      $(this).parents(".opt").removeClass("focus");
    });
    $("#btnMenu").on("click", () => {
      if (!$(".gnb:visible").length) {
        $("<div/>", {
          class: "trans-wrap",
        })
          .appendTo("body")
          .fadeIn(200);
      }
      $menuAll.addClass("on");
      $nav.addClass("on");

      $(".trans-wrap").on("click", function () {
        $menuAll.removeClass("on");
        $nav.removeClass("on");
        if ($(".trans-wrap").length) {
          $(".trans-wrap").fadeOut(200, function () {
            $(this).remove();
          });
        }
      });
    });

    // menu-all
    // function menuAll(el: any) {
    //   if (!$(".gnb:visible").length) {
    //     $("<div/>", {
    //       class: "trans-wrap",
    //     })
    //       .appendTo("body")
    //       .fadeIn(200);
    //   }
    //   $menuAll.addClass("on");
    //   $nav.addClass("on");

    //   $(".trans-wrap").on("click", function () {
    //     $menuAll.removeClass("on");
    //     $nav.removeClass("on");
    //     if ($(".trans-wrap").length) {
    //       $(".trans-wrap").fadeOut(200, function () {
    //         $(this).remove();
    //       });
    //     }
    //   })
    // }

    // 버튼 focus 처리
    $btn.hover(
      function () {},
      function () {
        $(this).blur();
      }
    );

    // 리사이즈시
    $(window).resize(function () {
      // pc 화면 체크
      var innerWidth: any;
      var chkPc: any;
      innerWidth = $(window).innerWidth();
      chkPc = innerWidth = innerWidth > 769 ? true : false;
      if (chkPc === true) {
        $(".wrap").addClass("pc");
      } else {
        $(".wrap").removeClass("pc");
      }

      popRespon();
    });

    // 레이어
    function openLayer(el: any) {
      var temp = $("#" + el);
      if (!$(".pop-layer:visible").length) {
        $("<div/>", {
          class: "dimmed",
        })
          .appendTo("body")
          .fadeIn(200);
      }
      temp.fadeIn(200);
      popRespon();
      $("html").addClass("full");
      //.dimmed 클래스 추가시 외부영역 클릭시 팝업 닫힘
      $(".popClose").on("click", function () {
        temp.fadeOut(200);
        if ($(".dimmed").length) {
          $(".dimmed").fadeOut(200, function () {
            $(this).remove();
          });
        }
        $("html").removeClass("full");
      });
    }

    // pop-layer 반응형 위치체크
    function popRespon() {
      var $popUp = $(".pop-layer");
      var $popWrap = $(".pop-layer__wrap");

      var popWrapHT: any;
      var winHT: any;
      popWrapHT = $popWrap.innerHeight();
      winHT = $(window).innerHeight();
      // console.log(popWrapHT + " / " + winHT);

      if (popWrapHT >= winHT) {
        $popUp.css("height", "100%");
      } else if (popWrapHT < winHT) {
        $popUp.css("height", "auto");
      }
    }
  }

  public openLayer = (el: any) => {
    var temp = $("#" + el);
    if (!$(".pop-layer:visible").length) {
      $("<div/>", {
        class: "dimmed",
      })
        .appendTo("body")
        .fadeIn(200);
    }
    temp.fadeIn(200);
    this.popRespon();
    $("html").addClass("full");

    $(".popClose").on("click", function () {
      temp.fadeOut(200);
      if ($(".dimmed").length) {
        $(".dimmed").fadeOut(200, function () {
          $(this).remove();
        });
      }
      $("html").removeClass("full");
    });
  };
  public popRespon() {
    var $popUp = $(".pop-layer");
    var $popWrap = $(".pop-layer__wrap");

    var popWrapHT: any;
    var winHT: any;
    popWrapHT = $popWrap.innerHeight();
    winHT = $(window).innerHeight();
    // console.log(popWrapHT + " / " + winHT);

    if (popWrapHT >= winHT) {
      $popUp.css("height", "100%");
    } else if (popWrapHT < winHT) {
      $popUp.css("height", "auto");
    }
  }
}
