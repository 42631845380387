import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import Login from "./components/Home/Login";
import Main from "./components/Main/Main";
import Patient from "./components/Patient/Patient";
import PatientDetail from "./components/Patient/PatientDetail";
import Medical from "./components/Medical/Medical";
import MedicalDetail from "./components/Medical/MedicalDetail";
import Device from "./components/Device/Device";
import DeviceDetail from "./components/Device/DeviceDetail";
import DeviceLink from "./components/Patient/DeviceLink";
import MedicalLink from "./components/Patient/MedicalLink";
import { DateBox } from "devextreme-react";
import VideoHistory from "./components/Etc/VideoHistory";
import Measurement from "./components/Etc/Measurement";
import { WebPage } from "./components/Etc/WebPage";
import LicenceList from "./components/LincenceList/LicenceList";
import Alert from "./components/Etc/Alert";
import SuperAdmin from "./components/Home/SuperAdmin";
import Assessment from "./components/Webview/Assessment";
import Education from "./components/Webview/Education";
import { lang } from "./lib/utils/language";
import { locale, loadMessages, formatMessage } from "devextreme/localization";
loadMessages(lang);
const start = async () => {
  locale("" + localStorage.getItem("lang"));
};
start();
class App extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/auth/login" component={Login} />
          <Redirect from="/" exact to="/auth/login" />
          <Route path="/licenceList" component={LicenceList} />
          <Route path="/assessment" component={Assessment} />
          <Route path="/education" component={Education} />

          <div className="comHeight">
            <div className="skip-navi">
              <a href="#content">본문 바로가기</a>
            </div>
            <div className="wrap">
              <header className="header">
                <div className="header__wrap">
                  <h1>
                    <a href="/main">
                      <span className="blind">Hicare</span>
                    </a>
                  </h1>

                  <div className="util">
                    <div className="icon-wrap">
                      <i
                        className="fa fa-user-o icon-img"
                        aria-hidden="true"
                      ></i>
                    </div>
                    <strong className="user-name">
                      <div id="zzid"> {localStorage.getItem("id")}</div>
                    </strong>
                    <button id="btnMenu" type="button" className="menu-all">
                      <i
                        className="fa fa-angle-down icon-arrow"
                        aria-hidden="true"
                      ></i>
                    </button>
                  </div>
                </div>

                <div className="gnb-wrap">
                  <nav className="gnb">
                    <ul className="depth01">
                      <li>
                        <a href="/main">
                          <i className="fa fa-home" aria-hidden="true"></i>
                          <span>{formatMessage("txt_home")}</span>
                        </a>
                      </li>
                      <li>
                        <p>
                          <i
                            className="fa fa-angle-down"
                            aria-hidden="true"
                          ></i>
                          <span>{formatMessage("txt_list_register")}</span>
                        </p>
                        <ul
                          className="depth02"
                          style={{
                            display:
                              localStorage.getItem("id") === "system"
                                ? "none"
                                : "",
                          }}
                        >
                          <li>
                            <a href="/patient">
                              <i className="fa fa-user" aria-hidden="true"></i>
                              <span>{formatMessage("txt_patient")}</span>
                            </a>
                          </li>
                          <li>
                            <a href="/device">
                              <i
                                className="fa fa-desktop"
                                aria-hidden="true"
                              ></i>
                              <span>{formatMessage("txt_device")}</span>
                            </a>
                          </li>
                          <li>
                            <a href="/medical">
                              <i
                                className="fa fa-stethoscope"
                                aria-hidden="true"
                              ></i>
                              <span>{formatMessage("txt_medical_staff")}</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <p>
                          <i
                            className="fa fa-angle-down"
                            aria-hidden="true"
                          ></i>
                          <span>{formatMessage("txt_links")}</span>
                        </p>
                        <ul
                          className="depth02"
                          style={{
                            display:
                              localStorage.getItem("id") === "system"
                                ? "none"
                                : "",
                          }}
                        >
                          <li>
                            <a href="/medicalLink">
                              <i
                                className="fa fa-video-camera"
                                aria-hidden="true"
                              ></i>
                              <span>{formatMessage("txt_patient_ms")}</span>
                            </a>
                          </li>
                          <li>
                            <a href="/deviceLink">
                              <i
                                className="fa fa-retweet"
                                aria-hidden="true"
                              ></i>
                              <span>{formatMessage("txt_patient_device")}</span>
                            </a>
                          </li>
                          <li>
                            <a href="/webpage">
                              <i className="fa fa-globe" aria-hidden="true"></i>
                              <span>{formatMessage("txt_webpage")}</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <p>
                          <i
                            className="fa fa-angle-down"
                            aria-hidden="true"
                          ></i>
                          <span>{formatMessage("txt_history")}</span>
                        </p>
                        <ul
                          className="depth02"
                          style={{
                            display:
                              localStorage.getItem("id") === "system"
                                ? "none"
                                : "",
                          }}
                        >
                          <li>
                            <a href="/videoHistory">
                              <i className="fa fa-list" aria-hidden="true"></i>
                              <span>Video Call History</span>
                            </a>
                          </li>
                          <li>
                            <a href="/measurement">
                              <i className="fa fa-list" aria-hidden="true"></i>
                              <span>Measurement History</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li
                        style={{
                          display:
                            localStorage.getItem("id") === "system"
                              ? "none"
                              : "",
                        }}
                      >
                        <p>
                          <i
                            className="fa fa-angle-down"
                            aria-hidden="true"
                          ></i>
                          <span>{formatMessage("txt_settings")}</span>
                        </p>
                        <ul className="depth02">
                          <li
                            style={{
                              display:
                                localStorage.getItem("id") === "system"
                                  ? "none"
                                  : "",
                            }}
                          >
                            <a href="/alert">
                              <i className="fa fa-list" aria-hidden="true"></i>
                              <span>{formatMessage("txt_alert_level")}</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a
                          href="/auth/login"
                          onClick={() => localStorage.removeItem("jwt")}
                        >
                          <i className="fa fa-sign-out" aria-hidden="true"></i>
                          <span>{formatMessage("txt_logout")}</span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </header>
              <Route path="/main" component={Main} />
              <Route path="/patient" component={Patient} />
              <Route path="/patientInfo/:id" component={PatientDetail} />
              <Route path="/device" component={Device} />
              <Route path="/deviceInfo/:id" component={DeviceDetail} />
              <Route path="/medical" component={Medical} />
              <Route path="/medicalInfo/:id" component={MedicalDetail} />
              <Route path="/deviceLink" component={DeviceLink} />
              <Route path="/medicalLink" component={MedicalLink} />
              <Route path="/videoHistory" component={VideoHistory} />
              <Route path="/measurement" component={Measurement} />
              <Route path="/webpage" component={WebPage} />
              <Route path="/alert" component={Alert} />
              <Route path="/superAdmin" component={SuperAdmin} />

              <footer className="footer">
                <div className="footer__wrap">
                  <p className="copy">
                    Copyright ©HicareNet Inc. All rights Reserved
                  </p>
                </div>
              </footer>
            </div>
          </div>
        </Switch>
      </Router>
    );
  }
}
export default App;
