/* react */
import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";

import { IMedicalLinkProps } from "./IMedicalLink";
import "jquery-ui";
import { Common } from "../../lib/utils/common";
import { useHistory, useParams } from "react-router-dom";

import {
  Button,
  DataGrid,
  DateBox,
  Popup,
  RadioGroup,
  SelectBox,
  TextArea,
  TextBox,
  TagBox,
} from "devextreme-react";
import * as _ from "lodash";
import { AdminDataProvider } from "../../lib/service/AdminDataProvider";
import moment from "moment";
import {
  Column,
  Pager,
  Paging,
  SearchPanel,
  LoadPanel,
} from "devextreme-react/data-grid";
import $ from "jquery";

import sBox from "devextreme/ui/tag_box";
import { alert } from "devextreme/ui/dialog";

export const MedicalLink: FunctionComponent<IMedicalLinkProps> = (props) => {
  const history = useHistory();
  const common = new Common();
  const param: any = useParams();
  const [doctor, setDoctor] = useState([] as any);
  const [patient, setPatient] = useState([] as any);
  const [doctorPatientRelation, setDoctorPatientRelation] = useState([] as any);
  /* componentDidMount && componentDidUpdate */
  useEffect(() => {
    common.ResizePage();
    const fetchArticles = async () => {
      const adminService = new AdminDataProvider();
      const main = await adminService.GetMainList();
      console.log(main.data.doctorPatientRelation);
      setPatient(main.data.patient);
      setDoctor(main.data.doctor);
      setDoctorPatientRelation(main.data.doctorPatientRelation);
    };

    if (localStorage.getItem("jwt") !== null) {
      fetchArticles();
    } else {
      history.push("/auth/login");
    }
  }, [props]);

  const _dev = (e: any, d: any) => {
    const id = document.getElementById(e) as HTMLElement;
    return d.getInstance(id);
  };
  const btnDeleteDoctorPatientRelation = async (d: any) => {
    const adminService = new AdminDataProvider();
    console.log(d);
    adminService.DeleteDoctorPatientRelation(d).then((e: any) => {
      history.push("/medicalLink");
    });
  };

  const btnSave = async (d: any) => {
    const selPatient = _dev("selPatient", sBox).option("value");
    const selDoctor = _dev("selDoctor", sBox).option("value");
    let json: any[] = [];

    for (var i = 0; i < selPatient.length; i++) {
      for (var z = 0; z < selDoctor.length; z++) {
        if (
          _.filter(doctorPatientRelation, {
            insungSeq: selPatient[i],
            doctorSeq: selDoctor[z],
          }).length === 0
        ) {
          json.push({
            insungSeq: selPatient[i],
            doctorSeq: selDoctor[z],
            inputUtcDt: new Date(),
          });
        }
      }
    }
    console.log(json);
    // const json = {
    //   insungSeq: selPatient,
    //   doctorSeq: selDoctor,
    // };
    const adminService = new AdminDataProvider();
    adminService.addDoctorRelation(json).then(async (e: any) => {
      _dev("selPatient", sBox).option("value", "");
      _dev("selDoctor", sBox).option("value", "");
      history.push("/medicalLink");
    });
  };

  return (
    <main id="content" className="content">
      <section className="sub-top">
        <header>
          <div className="page-history">
            <ul>
              <li>
                <a href="/main">
                  <i className="fa fa-home" aria-hidden="true"></i>
                  <span className="blind">home</span>
                </a>
              </li>
              <li className="current">
                <a href={window.location.href}>
                  <span>Patient - Medical Staff</span>
                </a>
              </li>
            </ul>
          </div>
          <h2>
            <span>Patient - Medical Staff</span>
            <span className="num-ea">{doctorPatientRelation.length}</span>
          </h2>
        </header>

        <article className="link-wrap">
          <div className="item">
            <h3>Patient List</h3>

            <TagBox
              dataSource={patient}
              id="selPatient"
              placeholder="선택"
              displayExpr="name"
              valueExpr="insungSeq"
              className="inp"
            />
          </div>
          <Button
            className="btn md blue"
            id="btnSave"
            text="Link"
            type="success"
            icon="fa fa-link"
            stylingMode="contained"
            // useSubmitBehavior={true}
            onClick={btnSave}
          />
          <div className="item">
            <h3>Medical Staff</h3>
            <TagBox
              dataSource={doctor}
              id="selDoctor"
              placeholder="선택"
              displayExpr="name"
              valueExpr="doctorSeq"
              className="inp"
            />
          </div>
        </article>
      </section>

      <section className="sub-cont search-mode">
        <header>
          <div className="header-item full">
            <ul className="icon-view">
              <li>
                <span className="badge sm blue-bg">Dr</span>
                <span>Doctor</span>
              </li>
              <li>
                <span className="badge sm dark-bg">Rp</span>
                <span>Receptionist</span>
              </li>
            </ul>
          </div>
        </header>

        <section className="sub-cont__wrap">
          <article>
            <div className="tbl-wrap">
              <DataGrid
                id="gridCont1"
                dataSource={doctorPatientRelation}
                hoverStateEnabled={true}
                className="type-ct"
                style={{ minWidth: 1000 }}
              >
                <LoadPanel enabled />
                <SearchPanel visible={true} highlightCaseSensitive={true} />
                <Column
                  caption="No"
                  width={100}
                  cellRender={(e) => {
                    return e.row.rowIndex + 1;
                  }}
                />
                <Column
                  dataField="name2"
                  caption="PATIENT NAME"
                  cellTemplate={(container: any, options: any) => {
                    $("<a  class='link' style='cursor:pointer'>")
                      .on("click", () => {
                        history.push("/patientInfo/" + options.data.insungSeq);
                      })
                      .append(options.value)
                      .appendTo(container);
                  }}
                />
                <Column
                  dataField="name"
                  caption="MEDICAL STAFF"
                  cellTemplate={(container: any, options: any) => {
                    $("<a class='link' style='cursor:pointer'>")
                      .on("click", () => {
                        history.push("/medicalInfo/" + options.data.doctorSeq);
                      })
                      .append(options.value)
                      .appendTo(container);
                  }}
                />

                <Column
                  dataField="inputUtcDt"
                  caption="LINKED DATE"
                  dataType="date"
                  format="yyyy-MM-dd"
                />
                <Column
                  dataField="STATUS"
                  caption="STATUS"
                  cellTemplate={(container: any, options: any) => {
                    $("<span class='link' style='cursor:pointer'>")
                      .on("click", () => {
                        btnDeleteDoctorPatientRelation(options.data.seq);
                      })
                      .append(
                        "<i class='fa fa-times' aria-hidden='true'>UNLINK</i>"
                      )
                      .appendTo(container);
                  }}
                />
                <Paging defaultPageSize={10} />
                <Pager
                  visible={true}
                  allowedPageSizes={[10, 20, "all"]}
                  displayMode={"full"}
                  showPageSizeSelector={true}
                  showInfo={true}
                  showNavigationButtons={true}
                />
              </DataGrid>
            </div>
          </article>
        </section>
      </section>
    </main>
  );
};

export default MedicalLink;
