/* react */
import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";

import { ISuperAdminProps } from "./ISuperAdmin";
import $ from "jquery";
import { Common } from "../../lib/utils/common";
import "devextreme/dist/css/dx.light.css";
import * as _ from "lodash";
import "../../css/font-awesome.min.css";
import "../../css/layout.css";

import "jquery-ui/themes/base/jquery-ui.css";
import {
  Button,
  DataGrid,
  DateBox,
  LoadPanel,
  RadioGroup,
  SelectBox,
  TextBox,
} from "devextreme-react";
import tBox from "devextreme/ui/text_box";
import sBox from "devextreme/ui/select_box";
import { useHistory } from "react-router";
import { AdminDataProvider } from "../../lib/service/AdminDataProvider";
import notify from "devextreme/ui/notify";
import { confirm } from "devextreme/ui/dialog";
import { Column } from "devextreme-react/data-grid";
import { alert } from "devextreme/ui/dialog";
export const SuperAdmin: FunctionComponent<ISuperAdminProps> = (props) => {
  const history = useHistory();
  const common = new Common();
  const [adminList, setAdminList] = useState([] as any);
  const [adminDetail, setAdminDetail] = useState({} as any);
  const [adminSeq, setAdminSeq] = useState(null as any);
  const adminService = new AdminDataProvider();
  useEffect(() => {
    common.ResizePage();
    const fetchArticles = async () => {
      const adminList = await adminService.GetAdminList();
      console.log(adminList);
      setAdminList(adminList.data);
      setAdminDetail(adminList.data[0]);
    };

    if (localStorage.getItem("id") === "system") {
      fetchArticles();
    } else {
      history.push("/auth/login");
    }
  }, [props]);
  const btnSave = async (d: any) => {
    const adminService = new AdminDataProvider();

    const txtInp1 = _dev("txtInp1", tBox).option("value");
    const txtInp2 = _dev("txtInp2", tBox).option("value");
    const txtInp3 = _dev("txtInp3", tBox).option("value");
    const txtInp4 = _dev("txtInp4", tBox).option("value");

    const selTest = _dev("selTest", sBox).option("value");
    let json;
    if (adminSeq === null) {
      json = {
        adminName: txtInp1,
        loginId: txtInp2,
        passWord: txtInp3,
        testYn: selTest,
      };
    } else {
      json = {
        adminSeq: adminSeq,
        passWord: txtInp3,
      };
    }

    if (txtInp3 === txtInp4) {
      console.log(json);
      adminService.addAdmin(json).then((e: any) => {
        history.push("/superAdmin");
      });
    } else {
      alert("입력 정보를 확인해주세요.", "경고");
    }
  };

  const btnDelete = async (d: any) => {
    const adminService = new AdminDataProvider();
    adminService.DeleteAdmin(d).then((e: any) => {
      history.push("/superAdmin");
    });
  };
  const _dev = (e: any, d: any) => {
    const id = document.getElementById(e) as HTMLElement;
    return d.getInstance(id);
  };

  return (
    <main id="content" className="content">
      <section className="super-area">
        <header className="super-top">
          <p className="alert-notice type-block">
            <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>
            <span>
              This page is Super Admin's page. The Super Admin can register only
              Company's administrator.
            </span>
          </p>
        </header>

        <div className="super-cont">
          <article className="total-item">
            <div className="item">
              <strong>{_.uniqBy(adminList, "adminName").length}</strong>
              <span>Company / Organization</span>
            </div>
            <div className="item">
              <strong>{adminList.length}</strong>
              <span>Total Administrator</span>
            </div>
            <div className="item">
              <strong>{adminDetail.adminName}</strong>
              <span>Recent Register Company</span>
            </div>
          </article>

          <div className="adm-wrap">
            <article className="adm-reg">
              <h2>ADMINISTRATOR REGISTRATION</h2>
              <ul className="adm-reg__insert">
                <li>
                  <p>
                    <i className="fa fa-briefcase" aria-hidden="true"></i>
                    <strong>Company / Organization</strong>
                  </p>
                  <div id="comName" className="inp"></div>
                  <TextBox
                    id="txtInp1"
                    className="inp"
                    placeholder="Company / Organization"
                  />
                </li>
                <li>
                  <p>
                    <i className="fa fa-user" aria-hidden="true"></i>
                    <strong>ID</strong>
                  </p>
                  <TextBox id="txtInp2" className="inp" placeholder="ID" />
                </li>
                <li>
                  <p>
                    <i className="fa fa-lock" aria-hidden="true"></i>
                    <strong>Password</strong>
                  </p>
                  <TextBox
                    id="txtInp3"
                    mode="password"
                    placeholder="PASSWORD"
                    className="inp"
                  />
                  <TextBox
                    id="txtInp4"
                    mode="password"
                    placeholder="PASSWORD"
                    className="inp mt10"
                  />
                </li>
                <li>
                  <p>
                    <i className="fa fa-lock" aria-hidden="true"></i>
                    <strong>test Group</strong>
                  </p>

                  <SelectBox
                    id="selTest"
                    items={["N", "Y"]}
                    defaultValue={"N"}
                    placeholder="N"
                  />
                </li>
                <li>
                  <Button
                    id="btnSave"
                    className="btn mlg blue"
                    text="Register"
                    type="success"
                    stylingMode="contained"
                    onClick={btnSave}
                  />
                </li>
              </ul>
            </article>
            <article className="adm-list">
              <h2>ADMINISTRATOR REGISTRATION</h2>
              <div className="tbl-wrap">
                <DataGrid
                  id="gridCont1"
                  dataSource={adminList}
                  hoverStateEnabled={true}
                  className="type-ct"
                >
                  <Column
                    caption="No"
                    width={50}
                    cellRender={(e) => {
                      return e.row.rowIndex + 1;
                    }}
                  />
                  <Column dataField="adminName" caption="COMPANY" />
                  <Column
                    dataField="PASSWORD SETTING	"
                    caption="PASSWORD SETTING	"
                    cellTemplate={(container: any, options: any) => {
                      $("<span>")
                        .on("click", () => {
                          common.openLayer("popPassword");

                          setAdminSeq(options.data.adminSeq);
                        })
                        .append(
                          $("<Button>", {
                            class: "btn sm blue",
                            type: "success",
                            text: "Password",
                          })
                        )
                        .appendTo(container);
                    }}
                  />
                  <Column dataField="loginId" caption="ID" />
                  <Column
                    caption="LEVEL"
                    cellRender={(e) => {
                      return "Administrator";
                    }}
                  />
                  <Column
                    dataField="testYn"
                    caption="TEST"
                    cellRender={(e) => {
                      return e.data.testYn === null ? "N" : "Y";
                    }}
                  />
                  <Column
                    caption="DELETE"
                    cellTemplate={(container: any, options: any) => {
                      $("<a  class='link' style='cursor:pointer'>")
                        .on("click", () => {
                          let result = confirm(
                            "<i>Are your sure you want to delete this account?</i>",
                            "Confirm changes"
                          );
                          result.then((dialogResult) => {
                            if (dialogResult) {
                              btnDelete(options.data.adminSeq);
                            } else {
                              alert("Canceled", "취소");
                            }
                          });
                        })
                        .append($("<i>", { class: "fa fa-trash" }))
                        .appendTo(container);
                    }}
                  />
                </DataGrid>
              </div>
            </article>
          </div>
        </div>
      </section>

      <div className="pop-layer pop-layer--md" id="popPassword">
        <section className="pop-layer__wrap">
          <header>
            <h3>
              <strong>Password Change</strong>
            </h3>
            <span className="icon icon--close lg popClose"></span>
          </header>

          <div className="cont">
            <div className="cont__wrap">
              <div className="form-wrap mb10">
                <i className="fa fa-lock mr5" aria-hidden="true"></i>
                <span className="w35 pr10">PASSWORD</span>
                <TextBox
                  id="txtPopPw"
                  mode="password"
                  placeholder="PASSWORD"
                  className="inp"
                />
              </div>
              <div className="form-wrap">
                <i className="fa fa-lock mr5" aria-hidden="true"></i>
                <span className="w35 pr10">PASSWORD CONFIRMED</span>
                <TextBox
                  id="txtPopPwc"
                  mode="password"
                  placeholder="PASSWORD"
                  className="inp mt10"
                />
              </div>
            </div>
          </div>

          <footer>
            <div className="btn-wrap">
              <Button
                id="btnPopClose"
                className="btn md grey"
                text="Close"
                type="default"
                stylingMode="contained"
                onClick={() => $(".popClose").trigger("click")}
              />
              <Button
                id="btnPopSave"
                className="btn md blue"
                text="Save"
                type="success"
                stylingMode="contained"
                onClick={btnSave}
              />
            </div>
          </footer>
        </section>
      </div>
    </main>
  );
};

export default SuperAdmin;
