/* react */
import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";

import { IVideoHistoryProps } from "./IVideoHistory";
import "jquery-ui";
import { Common } from "../../lib/utils/common";
import { useHistory } from "react-router-dom";

import {
  Button,
  DataGrid,
  DateBox,
  LoadPanel,
  Popup,
  RadioGroup,
  SelectBox,
  TextArea,
  TextBox,
} from "devextreme-react";
import * as _ from "lodash";
import { Column, Pager, Paging, SearchPanel } from "devextreme-react/data-grid";
import { AdminDataProvider } from "../../lib/service/AdminDataProvider";
import $ from "jquery";
export const VideoHistory: FunctionComponent<IVideoHistoryProps> = (props) => {
  const history = useHistory();
  const common = new Common();

  const [videoConsultationHistory, setVideoConsultationHistory] = useState(
    [] as any
  );
  /* componentDidMount && componentDidUpdate */
  useEffect(() => {
    const fetchArticles = async () => {
      common.ResizePage();
      const adminService = new AdminDataProvider();
      const main = await adminService.GetMainList();
      console.log(main.data.videoConsultationHistory);
      setVideoConsultationHistory(main.data.videoConsultationHistory);
    };

    if (localStorage.getItem("id") === "system") {
      history.push("/superAdmin");
    } else if (localStorage.getItem("jwt") !== null) {
      fetchArticles();
    } else {
      history.push("/auth/login");
    }
  }, [props]);

  return (
    <main id="content" className="content">
      <section className="sub-top">
        <header>
          <div className="page-history">
            <ul>
              <li>
                <a href="#">
                  <i className="fa fa-home" aria-hidden="true"></i>
                  <span className="blind">home</span>
                </a>
              </li>
              <li className="current">
                <a href="#">
                  <span>Video Call History</span>
                </a>
              </li>
            </ul>
          </div>
          <h2>Video Call History</h2>
        </header>
      </section>

      <section className="sub-cont">
        <section className="sub-cont__wrap">
          <article>
            <div className="tbl-wrap">
              <DataGrid
                id="gridCont1"
                dataSource={videoConsultationHistory}
                hoverStateEnabled={true}
                className="type-ct"
                style={{ minWidth: 1000 }}
              >
                <SearchPanel visible={true} highlightCaseSensitive={true} />
                <Column
                  caption="No"
                  width={50}
                  cellRender={(e) => {
                    return e.row.rowIndex + 1;
                  }}
                />
                <Column dataField="name" caption="CALLER NAME" />
                <Column dataField="name2" caption="CALLEE NAME" />
                <Column
                  dataField="STATUS"
                  caption="STATUS"
                  cellTemplate={(container: any, options: any) => {
                    // 상태값에 따라 success, fail, missed 클래스를 넣어주세요.
                    const accept = options.data.acceptUtcDt;
                    const end = options.data.endUtcDt;

                    let stClass;
                    let stValue;
                    if (accept === "Invalid date" && end === "Invalid date") {
                      stClass = "fail";
                      stValue = "Fail";
                    } else if (
                      accept === "Invalid date" ||
                      end === "Invalid date"
                    ) {
                      stClass = "missed";
                      stValue = "Missed";
                    } else {
                      stClass = "success";
                      stValue = "Success";
                    }
                    $("<span class='icon-status " + stClass + "'>")
                      .append(stValue)
                      .appendTo(container);
                  }}
                />
                <Column dataField="startUtcDt" caption="START TIME" />
                <Column dataField="acceptUtcDt" caption="ACCEPT TIME" />
                <Column dataField="endUtcDt" caption="END TIME" />
                <Column
                  dataField="duration"
                  caption="Duration"
                  cellTemplate={(container: any, options: any) => {
                    console.log(options.value);
                    const str =
                      (options.value.minutes > 0 ? options.value.minutes : 0) +
                      " min " +
                      (options.value.seconds > 0 ? options.value.seconds : 0) +
                      " sec";
                    $("<span>").append(str).appendTo(container);
                  }}
                />
                <Paging defaultPageSize={10} />
                <Pager
                  visible={true}
                  allowedPageSizes={[10, 20]}
                  displayMode={"full"}
                  showPageSizeSelector={true}
                  showInfo={true}
                  showNavigationButtons={true}
                />
              </DataGrid>
            </div>
          </article>
        </section>
      </section>
    </main>
  );
};

export default VideoHistory;
