/* react */
import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";

import { IPatientDetailProps } from "./IPatientDetail";
import "jquery-ui";
import { Common } from "../../lib/utils/common";
import { useHistory, useParams } from "react-router-dom";

import {
  Button,
  DateBox,
  LoadPanel,
  Popup,
  RadioGroup,
  SelectBox,
  TextArea,
  TextBox,
  Validator,
} from "devextreme-react";
import * as _ from "lodash";

import $ from "jquery";
import { AdminDataProvider } from "../../lib/service/AdminDataProvider";
import tBox from "devextreme/ui/text_box";
import dBox from "devextreme/ui/date_box";
import rGroup from "devextreme/ui/radio_group";
import { alert, confirm } from "devextreme/ui/dialog";
import DataGrid, { Column, RequiredRule } from "devextreme-react/data-grid";
import moment from "moment";
import { lang } from "../../lib/utils/language";
import { locale, loadMessages, formatMessage } from "devextreme/localization";
loadMessages(lang);
locale("" + localStorage.getItem("lang"));
export const PatientDetailProps: FunctionComponent<IPatientDetailProps> = (
  props
) => {
  const history = useHistory();
  const common = new Common();
  const param: any = useParams();
  const [patient, setPatient] = useState([] as any);
  const [deviceRelations, setDeviceRelations] = useState([] as any);
  const [doctorPatientRelation, setDoctorPatientRelation] = useState([] as any);
  /* componentDidMount && componentDidUpdate */
  useEffect(() => {
    common.ResizePage();
    const fetchArticles = async () => {
      const adminService = new AdminDataProvider();
      const main = await adminService.GetMainList();
      console.log(main.data.patient);
      const dtr = _.filter(main.data.doctorPatientRelation, {
        insungSeq: parseInt(param.id),
      });
      const dr = _.filter(main.data.deviceRelations, {
        insungSeq: parseInt(param.id),
      });

      if (dtr.length > 0) {
        setDoctorPatientRelation(dtr);
      }
      if (dr.length > 0) {
        setDeviceRelations(dr);
      }

      setPatient(
        _.filter(main.data.patient, { insungSeq: parseInt(param.id) })[0]
      );
      const a = _.filter(main.data.patient, {
        insungSeq: parseInt(param.id),
      })[0];
      console.log(a);
      _dev("txtFirstName", tBox).option("value", a.firstName);
      _dev("txtMidName", tBox).option("value", a.middleName);
      _dev("txtLastName", tBox).option("value", a.lastName);
      _dev("txtId", tBox).option("value", a.loginId);
      _dev("txtPID", tBox).option("value", a.pid);
      _dev("dateBirth", dBox).option("value", a.dob);
      console.log(a.gender);
      _dev("rdgSex", rGroup).option(
        "value",
        a.gender === "F" ? "Female" : "Male"
      );
    };

    if (localStorage.getItem("id") === "system") {
      history.push("/superAdmin");
    } else if (localStorage.getItem("jwt") !== null) {
      fetchArticles();
    } else {
      history.push("/auth/login");
    }
  }, [props]);

  const sex = ["Female", "Male"];

  const _dev = (e: any, d: any) => {
    const id = document.getElementById(e) as HTMLElement;
    return d.getInstance(id);
  };

  const btnSave = async (d: any) => {
    const adminService = new AdminDataProvider();

    const txtFirstName = _dev("txtFirstName", tBox).option("value");
    const txtMidName = _dev("txtMidName", tBox).option("value");
    const txtLastName = _dev("txtLastName", tBox).option("value");
    const txtId = _dev("txtId", tBox).option("value");
    const txtPID = _dev("txtPID", tBox).option("value");
    const txtPW = _dev("txtPW", tBox).option("value");
    const dateBirth = _dev("dateBirth", dBox).option("value");
    const rdgSex = _dev("rdgSex", rGroup).option("value");

    const json = {
      insungSeq: param.id,
      txtFirstName: txtFirstName,
      txtMidName: txtMidName,
      txtLastName: txtLastName,
      txtId: txtId,
      txtPW: txtPW,
      txtPID: txtPID,
      dateBirth: dateBirth,
      rdgSex: rdgSex.charAt(0),
    };

    if (d.validationGroup.validate().isValid) {
      let result = confirm(
        "<i>" + formatMessage("msg_edit_patient") + "</i>",
        "Confirm changes"
      );
      result.then((dialogResult) => {
        if (dialogResult) {
          adminService.addPatient(json).then((e: any) => {
            alert(formatMessage("msg_changed"), "Save");
            d.validationGroup.reset();
            history.push("/patient");
          });
        } else {
        }
      });
    } else {
      alert(formatMessage("msg_cancel"), "Canceld");
    }
  };

  const btnDeletePatient = async (d: any) => {
    const adminService = new AdminDataProvider();
    let result = confirm(
      "<i>" + formatMessage("msg_delete_patient") + "</i>",
      "Confirm changes"
    );
    result.then((dialogResult) => {
      if (dialogResult) {
        adminService.DeletePatient(param.id).then((e: any) => {
          alert(formatMessage("msg_deleted"), "Delete");
          history.push("/patient");
        });
      } else {
        alert(formatMessage("msg_cancel"), "Canceld");
      }
    });
  };
  const btnDeleteDoctorPatientRelation = async (d: any) => {
    const adminService = new AdminDataProvider();
    alert(formatMessage("msg_deleted"), "Delete");
    adminService.DeleteDoctorPatientRelation(d).then((e: any) => {
      history.push("/patientInfo/" + param.id);
    });
  };
  const btnDeleteDeviceRelations = async (d: any) => {
    const adminService = new AdminDataProvider();
    adminService.DeleteDeviceRelations(d).then((e: any) => {
      history.push("/patientInfo/" + param.id);
    });
  };

  return (
    <main id="content" className="content">
      <section className="sub-top">
        <header>
          <div className="page-history">
            <ul>
              <li>
                <a href="/main">
                  <i className="fa fa-home" aria-hidden="true"></i>
                  <span className="blind">home</span>
                </a>
              </li>
              <li>
                <a href="/patient">
                  <span>Patient</span>
                </a>
              </li>
              <li className="current">
                <a href={window.location.href}>
                  <span>Patient Info.</span>
                </a>
              </li>
            </ul>
          </div>
          <h2>Patient Info.</h2>
          <article className="info-wrap">
            <header>
              <img
                src="../images/glyphicons/glyphicons_035_woman.png"
                alt="images info text"
              />
              <div className="text-wrap">
                <p>
                  <strong>{patient.loginId}</strong>
                  <span>
                    ({patient.gender} / {patient.age})
                  </span>
                </p>
              </div>
            </header>
          </article>
        </header>

        <article className="reg-form info">
          <form action="" name="regForm">
            <fieldset>
              <legend className="blind">Patient Info.</legend>
              <article>
                <header>
                  <span>NAME</span>
                  <span className="req"></span>
                </header>
                <div className="cont">
                  <TextBox
                    id="txtFirstName"
                    placeholder="FirstName"
                    className="inp"
                  >
                    <Validator>
                      <RequiredRule message="FirstName is required" />
                    </Validator>
                  </TextBox>
                  {/* <div id="midName" className="inp"></div> */}
                  <TextBox
                    id="txtMidName"
                    placeholder="MidName"
                    className="inp"
                  ></TextBox>
                  {/* <div id="lastName" className="inp"></div> */}
                  <TextBox
                    id="txtLastName"
                    placeholder="LastName"
                    className="inp"
                  >
                    <Validator>
                      <RequiredRule message="LastName is required" />
                    </Validator>
                  </TextBox>
                </div>
              </article>
              <article>
                <header>
                  <span>ID</span>
                  <span className="req"></span>
                </header>
                <div className="cont">
                  <TextBox id="txtId" placeholder="ID" className="inp">
                    <Validator>
                      <RequiredRule message="ID is required" />
                    </Validator>
                  </TextBox>
                </div>
              </article>
              <article>
                <header>
                  <span>PASSWORD</span>
                  <span className="req"></span>
                </header>
                <div className="cont">
                  <TextBox
                    id="txtPW"
                    placeholder="Password"
                    className="inp"
                    mode="password"
                  />

                  <Button
                    className="btn md ml10"
                    id="btnChange"
                    text="Password Change"
                    type="default"
                    stylingMode="contained"
                    // useSubmitBehavior={true}
                    onClick={(e: any) => {
                      btnSave(e);
                    }}
                  />
                </div>
              </article>
              <article>
                <header>
                  <span>BIRTH</span>
                  <span className="req"></span>
                </header>
                <div className="cont">
                  <DateBox
                    id="dateBirth"
                    defaultValue={new Date()}
                    type="date"
                  />
                </div>
              </article>
              <article>
                <header>
                  <span>GENDER</span>
                  <span className="req"></span>
                </header>
                <div className="cont full">
                  <RadioGroup
                    id="rdgSex"
                    items={sex}
                    defaultValue={sex[0]}
                    layout="horizontal"
                    className="ans"
                  />
                </div>
              </article>
              <article>
                <header>
                  <span>PID(LINK ID)</span>
                </header>
                <div className="cont">
                  <TextBox id="txtPID" placeholder="PID" className="inp" />
                </div>
              </article>
              <article>
                <header>
                  <span>REGISTER DATE</span>
                </header>
                <div className="cont">
                  <span>{moment(patient.inputUtcDt).format("DD/MM/YYYY")}</span>
                </div>
              </article>
              <article>
                <header>
                  <span>Last UPDATE</span>
                </header>
                <div className="cont">
                  <span>
                    {moment(patient.updateUtcDt).format("DD/MM/YYYY")}
                  </span>
                </div>
              </article>
              <article>
                <header>
                  <span>QRCODE</span>
                </header>
                <div className="cont vert">
                  <span className="img-wrap">
                    <img src="../../images/qr.jpeg" alt="image info text" />
                  </span>
                  <div id="printBtn" className="btn md inline"></div>
                </div>
              </article>
              <article>
                <header>
                  <span>BARCODE</span>
                </header>
                <div className="cont vert">
                  <span className="img-wrap">
                    <img src="../../images/bar.png" alt="image info text" />
                  </span>
                  <div id="printBtn2" className="btn md inline"></div>
                </div>
              </article>
              <footer>
                <Button
                  className="btn md flex-mla"
                  id="btnSave"
                  text="SAVE"
                  type="success"
                  icon="fa fa-check"
                  stylingMode="contained"
                  // useSubmitBehavior={true}
                  onClick={(e: any) => {
                    btnSave(e);
                  }}
                />
                <Button
                  className="btn md flex-mla ml10"
                  id="btnDelete"
                  text="DELETE"
                  type="success"
                  icon="fa fa-trash-o"
                  stylingMode="contained"
                  // useSubmitBehavior={true}
                  onClick={btnDeletePatient}
                />
              </footer>
            </fieldset>
          </form>
        </article>
      </section>

      <section className="sub-cont">
        <header className="line">
          <h2>Linked Info.</h2>
        </header>

        <section className="sub-cont__wrap">
          <article>
            <h3>
              <strong>Devices</strong>
              <span className="num-ea">{deviceRelations.length}</span>
            </h3>
            <div className="tbl-wrap">
              <DataGrid
                id="gridCont1"
                dataSource={deviceRelations}
                noDataText="No data"
                selection={{ mode: "single" }}
                hoverStateEnabled={true}
                className="type-ct"
              >
                <Column
                  caption="No"
                  width={50}
                  cellRender={(e) => {
                    return e.row.rowIndex + 1;
                  }}
                />
                <Column dataField="deviceType" caption="TYPE" />
                <Column
                  dataField="deviceId"
                  caption="S/N(Phone Number)"
                  cellTemplate={(container: any, options: any) => {
                    $("<a class='link'>")
                      .append(options.value)
                      .appendTo(container);
                  }}
                />
                <Column
                  dataField="inputUtcDt"
                  caption="LINKED DATE"
                  dataType="date"
                  format="yyyy-MM-dd"
                />
                <Column
                  dataField="STATUS"
                  caption="STATUS"
                  cellTemplate={(container: any, options: any) => {
                    $("<span class='link' style='cursor:pointer'>")
                      .on("click", () => {
                        btnDeleteDeviceRelations(options.data.seq);
                      })
                      .append(
                        "<i class='fa fa-times' aria-hidden='true'>UNLINK</i>"
                      )
                      .appendTo(container);
                  }}
                />
              </DataGrid>
            </div>
          </article>
          <article>
            <h3>
              <strong>Medical Staff</strong>
              <span className="num-ea">{doctorPatientRelation.length}</span>
            </h3>
            <div className="tbl-wrap">
              <DataGrid
                id="gridCont2"
                dataSource={doctorPatientRelation}
                noDataText="No data"
                selection={{ mode: "single" }}
                hoverStateEnabled={true}
                className="type-ct"
              >
                <Column
                  caption="No"
                  width={50}
                  cellRender={(e) => {
                    return e.row.rowIndex + 1;
                  }}
                />
                <Column
                  dataField="name"
                  caption="NAME/ID"
                  cellTemplate={(container: any, options: any) => {
                    $("<a class='link'>")
                      .append(
                        "<i class='fa fa-user-md' aria-hidden='true'> " +
                          options.value +
                          "</i>"
                      )
                      .appendTo(container);
                  }}
                />
                <Column
                  dataField="SERVICE"
                  caption="SERVICE"
                  cellTemplate={(container: any, options: any) => {
                    $("<span class='icon'>")
                      .append($("<i>", { class: "fa fa-bar-chart" }))
                      .appendTo(container)
                      .append("<span>VideoCall</span></br>")
                      .appendTo(container)
                      .append($("<i>", { class: "fa fa-video-camera" }))
                      .appendTo(container)
                      .append("<span>WebChart</span>")
                      .appendTo(container);
                  }}
                />
                <Column
                  dataField="inputUtcDt"
                  caption="LINKED DATE"
                  dataType="date"
                  format="yyyy-MM-dd"
                />
                <Column
                  dataField="STATUS"
                  caption="STATUS"
                  cellTemplate={(container: any, options: any) => {
                    $("<span class='link' style='cursor:pointer'>")
                      .on("click", () => {
                        btnDeleteDoctorPatientRelation(options.data.seq);
                      })
                      .append(
                        "<i class='fa fa-times' aria-hidden='true'>UNLINK</i>"
                      )
                      .appendTo(container);
                  }}
                />
              </DataGrid>
            </div>
          </article>
        </section>
      </section>
    </main>
  );
};

export default PatientDetailProps;
