/* react */
import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";

import { IWebPageProps } from "./IWebPage";
import "jquery-ui";
import { Common } from "../../lib/utils/common";
import { useHistory } from "react-router-dom";

import {
  Button,
  DataGrid,
  DateBox,
  LoadPanel,
  Popup,
  RadioGroup,
  SelectBox,
  TextArea,
  TextBox,
} from "devextreme-react";
import * as _ from "lodash";
import {
  Column,
  Editing,
  Pager,
  Paging,
  SearchPanel,
} from "devextreme-react/data-grid";
import { AdminDataProvider } from "../../lib/service/AdminDataProvider";
import $ from "jquery";
import tBox from "devextreme/ui/text_box";
export const WebPage: FunctionComponent<IWebPageProps> = (props) => {
  const history = useHistory();
  const common = new Common();
  const [adminValue, setAdminValue] = useState([] as any);
  const [txtDisabled, setTxtDisabled] = useState(true);
  const [txtDisabled2, setTxtDisabled2] = useState(true);
  /* componentDidMount && componentDidUpdate */
  useEffect(() => {
    const fetchArticles = async () => {
      common.ResizePage();
      const adminService = new AdminDataProvider();
      const main = await adminService.GetMainList();
      setAdminValue(main.data.admin[0]);
    };

    if (localStorage.getItem("id") === "system") {
      history.push("/superAdmin");
    } else if (localStorage.getItem("jwt") !== null) {
      fetchArticles();
    } else {
      history.push("/auth/login");
    }
  }, [props]);
  const _dev = (e: any, d: any) => {
    const id = document.getElementById(e) as HTMLElement;
    return d.getInstance(id);
  };
  const btnSave = async (d: any) => {
    const adminService = new AdminDataProvider();

    const txtInp1 = _dev("txtInp1", tBox).option("value");
    const txtInp2 = _dev("txtInp2", tBox).option("value");

    const json = {
      txtInp1: txtInp1,
      txtInp2: txtInp2,
    };
    adminService.addWebPage(json).then((e: any) => {
      history.push("/webpage");
    });
  };
  return (
    <main id="content" className="content">
      <section className="sub-top">
        <header>
          <div className="page-history">
            <ul>
              <li>
                <a href="/main">
                  <i className="fa fa-home" aria-hidden="true"></i>
                  <span className="blind">home</span>
                </a>
              </li>
              <li className="current">
                <a href="/webpage">
                  <span>Webpage</span>
                </a>
              </li>
            </ul>
          </div>
          <h2>General Settings</h2>
        </header>
      </section>

      <section className="sub-cont__wrap">
        <article>
          <div className="tbl-wrap mt50">
            <table className="type-ct" style={{ minWidth: 1000 }}>
              <caption>Summary</caption>
              <colgroup>
                <col width="10%" />
                <col width="18%" />
                <col />
                <col width="15%" />
              </colgroup>
              <thead>
                <tr>
                  <th scope="col">NO</th>
                  <th scope="col">WEBPAGE</th>
                  <th scope="col">ADDRESS(URL)</th>
                  <th scope="col">REGISTER DATE</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td scope="row" className="fst">
                    1
                  </td>
                  <td>Assessment</td>
                  <td>
                    <div className="form-wrap">
                      <TextBox
                        id="txtInp1"
                        className="inp"
                        value={adminValue.assessUrl}
                        disabled={txtDisabled}
                      ></TextBox>
                      <Button
                        className="btn md blue ml5"
                        id="btnVerify"
                        text="Verify"
                        type="success"
                        icon="fa fa-check"
                        stylingMode="contained"
                      />
                      <Button
                        className="btn md grey ml5"
                        id="btnEdit"
                        text="Edit"
                        type="default"
                        icon="fa fa-pencil"
                        stylingMode="contained"
                        visible={txtDisabled}
                        onClick={() => {
                          if (txtDisabled) {
                            setTxtDisabled(false);
                          } else {
                            setTxtDisabled(true);
                          }
                        }}
                      />
                      <Button
                        className="btn md blue ml5"
                        text="Regist"
                        type="success"
                        icon="fa fa-check"
                        stylingMode="contained"
                        visible={!txtDisabled}
                        onClick={btnSave}
                      />
                    </div>
                  </td>
                  <td>11/25/2020</td>
                </tr>
                <tr>
                  <td scope="row" className="fst">
                    1
                  </td>
                  <td>Education</td>
                  <td>
                    <div className="form-wrap">
                      <TextBox
                        id="txtInp2"
                        className="inp"
                        value={adminValue.educationUrl}
                        disabled={txtDisabled2}
                      ></TextBox>
                      <Button
                        className="btn md blue ml5"
                        id="btnVerify"
                        text="Verify"
                        type="success"
                        icon="fa fa-check"
                        stylingMode="contained"
                      />
                      <Button
                        className="btn md grey ml5"
                        id="btnEdit"
                        text="Edit"
                        type="default"
                        icon="fa fa-pencil"
                        stylingMode="contained"
                        visible={txtDisabled2}
                        onClick={() => {
                          if (txtDisabled2) {
                            setTxtDisabled2(false);
                          } else {
                            setTxtDisabled2(true);
                          }
                        }}
                      />
                      <Button
                        className="btn md blue ml5"
                        text="Regist"
                        type="success"
                        icon="fa fa-check"
                        stylingMode="contained"
                        visible={!txtDisabled2}
                        onClick={btnSave}
                      />
                    </div>
                  </td>
                  <td>11/25/2020</td>
                </tr>
              </tbody>
            </table>
          </div>
        </article>
      </section>
    </main>
  );
};

export default WebPage;
