/* react */
import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";

import { IDeviceProps } from "./IDevice";
import "jquery-ui";
import { Common } from "../../lib/utils/common";
import { useHistory } from "react-router-dom";

import {
  Button,
  DataGrid,
  DateBox,
  Popup,
  RadioGroup,
  SelectBox,
  TextArea,
  TextBox,
} from "devextreme-react";
import * as _ from "lodash";

import {
  Column,
  Pager,
  Paging,
  SearchPanel,
  LoadPanel,
} from "devextreme-react/data-grid";
import { AdminDataProvider } from "../../lib/service/AdminDataProvider";
import $ from "jquery";
export const DeviceProps: FunctionComponent<IDeviceProps> = (props) => {
  const history = useHistory();
  const common = new Common();
  const [devices, setDevices] = useState([] as any);
  const [deviceRelations, setDeviceRelations] = useState([] as any);
  /* componentDidMount && componentDidUpdate */
  useEffect(() => {
    const fetchArticles = async () => {
      common.ResizePage();
      const adminService = new AdminDataProvider();
      const main = await adminService.GetMainList();

      setDevices(main.data.devices);
      setDeviceRelations(main.data.deviceRelations);
    };

    if (localStorage.getItem("id") === "system") {
      history.push("/superAdmin");
    } else if (localStorage.getItem("jwt") !== null) {
      fetchArticles();
    } else {
      history.push("/auth/login");
    }
  }, [props]);

  return (
    <main id="content" className="content">
      <section className="sub-top">
        <header>
          <div className="page-history">
            <ul>
              <li>
                <a href="/main">
                  <i className="fa fa-home" aria-hidden="true"></i>
                  <span className="blind">home</span>
                </a>
              </li>
              <li className="current">
                <a href="/device">
                  <span>Device</span>
                </a>
              </li>
            </ul>
          </div>
          <h2>Device List</h2>
        </header>
      </section>

      <section className="sub-cont">
        <section className="sub-cont__wrap">
          <article>
            <div className="tbl-wrap">
              <DataGrid
                id="gridCont1"
                dataSource={devices}
                hoverStateEnabled={true}
                className="type-ct"
                style={{ minWidth: 1000 }}
              >
                <LoadPanel enabled />
                <SearchPanel visible={true} highlightCaseSensitive={true} />
                <Column
                  caption="No"
                  width={50}
                  cellRender={(e) => {
                    return e.row.rowIndex + 1;
                  }}
                />
                <Column dataField="devices_device_type" caption="TYPE" />
                <Column
                  dataField="devices_device_id"
                  caption="S/N(Phone Number)"
                  cellTemplate={(container: any, options: any) => {
                    $(
                      "<a href='" +
                        "/deviceInfo/" +
                        options.value +
                        "' class='link'>"
                    )
                      .append(options.value)
                      .appendTo(container);
                  }}
                />
                <Column
                  dataField="deviceType"
                  caption="LINKED PATIENTS"
                  cellTemplate={(container: any, options: any) => {
                    const doc = _.filter(deviceRelations, {
                      deviceId: options.data.devices_device_id,
                    }).length;
                    $("<span>")
                      .append("" + doc)
                      .appendTo(container);
                  }}
                />
                <Column dataField="devices_input_dt" caption="REGISTER DATE" />

                <Paging defaultPageSize={10} />
                <Pager
                  visible={true}
                  allowedPageSizes={[10, 20, "all"]}
                  displayMode={"full"}
                  showPageSizeSelector={true}
                  showInfo={true}
                  showNavigationButtons={true}
                />
              </DataGrid>
            </div>
          </article>
        </section>
      </section>
    </main>
  );
};

export default DeviceProps;
