/* react */
import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";
import DataGrid, {
  Column,
  Editing,
  SearchPanel,
} from "devextreme-react/data-grid";

import { ILicenceListProps } from "./ILicenceList";
import { HLOListDataProvider } from "../../lib/service/HLOListDataProvider";
import { LeftMenu } from "../LeftMenu/LeftMenu";
import Drawer from "devextreme-react/drawer";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
export const LicenceList: FunctionComponent<ILicenceListProps> = (props) => {
  const hLOListDataProvider = new HLOListDataProvider();
  const [selectedDocuments, setSelectedDocuments] = useState([]);

  /* componentDidMount && componentDidUpdate */
  useEffect(() => {
    const element = document.getElementsByClassName("proepm-main")[0];
    element.scrollIntoView();

    const fetchArticles = async () => {
      const list = await hLOListDataProvider.GetHicareLicence();

      setSelectedDocuments(list);
    };
    fetchArticles();
  }, [props]);

  const onRowInserted = (e: any) => {
    hLOListDataProvider.addHicareLicence(e.data);
  };

  const onRowUpdated = (e: any) => {
    hLOListDataProvider.addHicareLicence(e.data);
  };
  const renderLeftMenu = () => {
    return <LeftMenu></LeftMenu>;
  };

  return (
    <div className="proepm-main">
      <Drawer
        opened={true}
        position="left"
        revealMode="slide"
        render={renderLeftMenu}
        className="proepm-content-box"
      >
        <div
          className="list-wrap"
          style={{
            marginTop: 46,
          }}
        >
          <div className="list-board">
            <div className="approval-list">
              <DataGrid
                id="gvApprovalList"
                dataSource={selectedDocuments}
                noDataText="No data"
                onRowInserted={onRowInserted}
                onRowUpdated={onRowUpdated}
                selection={{ mode: "single" }}
              >
                <Editing mode="row" allowAdding={true} allowUpdating={true} />
                <SearchPanel
                  visible={true}
                  width={240}
                  placeholder="Search..."
                />
                <Column dataField="licenceCompanyName" caption="회사명" />
                <Column dataField="licenceManager" caption="담당자" />
                <Column dataField="licenceEmail" caption="이메일" />
                <Column dataField="licenceTelNumber" caption="전화번호" />
                <Column
                  dataField="licenceIssueDate"
                  caption="발급일자"
                  dataType="date"
                  format="yyyy-MM-dd hh:mm:ss"
                />
                <Column
                  dataField="licenceExpirationDate"
                  caption="만료기한"
                  dataType="date"
                  format="yyyy-MM-dd hh:mm:ss"
                />
                <Column
                  dataField="licencePatientNumber"
                  width={80}
                  caption="환자수"
                />
                <Column dataField="licenceKey" caption="라이센스키" />
                <Column
                  dataField="licenceInused"
                  width={80}
                  caption="사용여부"
                />
              </DataGrid>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default LicenceList;
