/* react */
import * as React from "react";
import { FunctionComponent, useEffect } from "react";

import { Nav } from "office-ui-fabric-react/lib/components/Nav/Nav";
import {
  INavLinkGroup,
  INavStyles,
} from "office-ui-fabric-react/lib/components/Nav";

/* settings */

/* devextreme */

import { Link } from "office-ui-fabric-react";

const navStyles: Partial<INavStyles> = {
  root: {
    width: 300,
    // height: 400,
    // boxSizing: "border-box",
    // border: "1px solid #eee",
    // overflowY: "auto",
  },
};
const navLinkGroups: INavLinkGroup[] = [
  {
    name: "라이센스 관리",
    links: [
      {
        key: "라이센스 목록",
        name: "라이센스 목록",
        url: "#/Lincence/LincenceList",
      },
      { key: "코드 관리", name: "코드 관리", url: "#/Management/codes" },
      { key: "사용자 관리", name: "사용자 관리", url: "#/Management/users" },
    ],
  },
];

export const LeftMenu: FunctionComponent<{}> = (props) => {
  /* componentDidMount && componentDidUpdate */
  useEffect(() => {
    const fetchArticles = async () => {};

    fetchArticles();
  }, []);

  return (
    <div className="lnb-wrap">
      <div className="lnb-title">
        <h1>
          <Link to="/">
            <i
              className="fa fa-home"
              aria-hidden="true"
              style={{
                marginLeft: 30,
              }}
            ></i>
          </Link>
        </h1>
      </div>

      <div style={{ background: "white" }}>
        <Nav styles={navStyles} groups={navLinkGroups} />
      </div>
    </div>
  );
};

export default LeftMenu;
