/* react */
import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";

import { IMedicalProps } from "./IMedical";
import "jquery-ui";
import { Common } from "../../lib/utils/common";
import { useHistory } from "react-router-dom";

import {
  Button,
  DateBox,
  Popup,
  RadioGroup,
  SelectBox,
  TextArea,
  TextBox,
  Validator,
} from "devextreme-react";
import $ from "jquery";
import { AdminDataProvider } from "../../lib/service/AdminDataProvider";

import { alert } from "devextreme/ui/dialog";
import * as _ from "lodash";
import DataGrid, {
  Column,
  Pager,
  Paging,
  RequiredRule,
  SearchPanel,
  LoadPanel,
} from "devextreme-react/data-grid";
import tBox from "devextreme/ui/text_box";
import dBox from "devextreme/ui/date_box";
import rGroup from "devextreme/ui/radio_group";
import { lang } from "../../lib/utils/language";
import { locale, loadMessages, formatMessage } from "devextreme/localization";
loadMessages(lang);
locale("" + localStorage.getItem("lang"));
export const Medical: FunctionComponent<IMedicalProps> = (props) => {
  const history = useHistory();
  const common = new Common();
  const [doctor, setDoctor] = useState([] as any);
  const [patient, setPatient] = useState([] as any);
  const [doctorPatientRelation, setDoctorPatientRelation] = useState([] as any);
  /* componentDidMount && componentDidUpdate */
  useEffect(() => {
    common.ResizePage();

    const fetchArticles = async () => {
      const adminService = new AdminDataProvider();
      const main = await adminService.GetMainList();

      setDoctorPatientRelation(main.data.doctorPatientRelation);
      setDoctor(main.data.doctor);
      setPatient(main.data.patient);
    };
    if (localStorage.getItem("id") === "system") {
      history.push("/superAdmin");
    } else if (localStorage.getItem("jwt") !== null) {
      fetchArticles();
    } else {
      history.push("/auth/login");
    }
  }, [props]);
  const _dev = (e: any, d: any) => {
    const id = document.getElementById(e) as HTMLElement;
    return d.getInstance(id);
  };
  const btnSave = async (d: any) => {
    const adminService = new AdminDataProvider();

    const txtFirstName = _dev("txtFirstName", tBox).option("value");
    const txtMidName = _dev("txtMidName", tBox).option("value");
    const txtLastName = _dev("txtLastName", tBox).option("value");
    const txtId = _dev("txtId", tBox).option("value");

    const txtPW = _dev("txtPW", tBox).option("value");
    const txtPWC = _dev("txtPWC", tBox).option("value");

    const rdgRole = _dev("rdgRole", rGroup).option("value");

    const json = {
      txtFirstName: txtFirstName,
      txtMidName: txtMidName,
      txtLastName: txtLastName,
      txtId: txtId,
      txtPW: txtPW,
      txtPWC: txtPWC,
      rdgRole: rdgRole.charAt(0),
    };
    if (d.validationGroup.validate().isValid) {
      if (txtPW !== txtPWC) {
        alert(formatMessage("msg_check_duplication"), "Warning");
      } else if (_.filter(doctor, { loginId: txtId }).length > 0) {
        alert(formatMessage("msg_password_not_match"), "Warning");
      } else {
        adminService.addDoctor(json).then(async (e: any) => {
          history.push("/medical");
        });
      }
    } else {
      alert(formatMessage("msg_error_occurred"), "Warning");
    }
  };

  return (
    <main id="content" className="content">
      <section className="sub-top">
        <header>
          <div className="page-history">
            <ul>
              <li>
                <a href="/main">
                  <i className="fa fa-home" aria-hidden="true"></i>
                  <span className="blind">home</span>
                </a>
              </li>
              <li className="current">
                <a href="/medical">
                  <span>Medical Staff</span>
                </a>
              </li>
            </ul>
          </div>
          <h2>Medical Staff Registration</h2>
        </header>

        <article className="reg-form insert">
          <form action="" name="regForm">
            <fieldset>
              <legend className="blind">Registration</legend>
              <article>
                <header>
                  <i className="fa fa-tasks" aria-hidden="true"></i>
                  <span>NAME</span>
                  <span className="req"></span>
                </header>
                <div className="cont">
                  <TextBox
                    id="txtFirstName"
                    placeholder="FirstName"
                    className="inp"
                  >
                    <Validator>
                      <RequiredRule message="FirstName is required" />
                    </Validator>
                  </TextBox>

                  <TextBox
                    id="txtMidName"
                    placeholder="MidName"
                    className="inp"
                  ></TextBox>

                  <TextBox
                    id="txtLastName"
                    placeholder="LastName"
                    className="inp"
                  >
                    <Validator>
                      <RequiredRule message="LastName is required" />
                    </Validator>
                  </TextBox>
                </div>
              </article>
              <article>
                <header>
                  <i className="fa fa-tasks" aria-hidden="true"></i>
                  <span>ID</span>
                  <span className="req"></span>
                </header>
                <div className="cont vert">
                  <TextBox id="txtId" placeholder="ID" className="inp">
                    <Validator>
                      <RequiredRule message="ID is required" />
                    </Validator>
                  </TextBox>
                  <p className="alert-info">
                    <i
                      className="fa fa-exclamation-triangle"
                      aria-hidden="true"
                    ></i>
                    <span>
                      Consider carefully before creating an account ID cannot
                      change.
                    </span>
                  </p>
                </div>
              </article>
              <article>
                <header>
                  <i className="fa fa-tasks" aria-hidden="true"></i>
                  <span>PASSWORD</span>
                  <span className="req"></span>
                </header>
                <div className="cont">
                  <TextBox
                    id="txtPW"
                    placeholder="Password"
                    className="inp"
                    mode="password"
                  >
                    <Validator>
                      <RequiredRule message="Password is required" />
                    </Validator>
                  </TextBox>
                  <TextBox
                    id="txtPWC"
                    mode="password"
                    placeholder="Password Confirmed"
                    className="inp"
                  >
                    <Validator>
                      <RequiredRule message="Password Confirmed is required" />
                    </Validator>
                  </TextBox>
                </div>
              </article>
              <article>
                <header>
                  <i className="fa fa-tasks" aria-hidden="true"></i>
                  <span>ROLE</span>
                  <span className="req"></span>
                </header>
                <div className="cont full">
                  <RadioGroup
                    id="rdgRole"
                    items={["Doctor", "Receptionist"]}
                    defaultValue={"Doctor"}
                    layout="horizontal"
                    className="ans"
                  />
                </div>
              </article>
              <footer>
                <Button
                  className="btn mlg flex-mla"
                  id="btnSave"
                  text="Register New"
                  type="success"
                  stylingMode="contained"
                  // useSubmitBehavior={true}
                  onClick={(e: any) => {
                    btnSave(e);
                  }}
                />
              </footer>
            </fieldset>
          </form>
        </article>
      </section>

      <section className="sub-cont search-mode">
        <header>
          <h2>Medical Staff List</h2>
          <div className="header-item">
            <ul className="icon-view">
              <li>
                <span className="badge sm blue-bg">Dr</span>
                <span>Doctor</span>
              </li>
              <li>
                <span className="badge sm dark-bg">Rp</span>
                <span>Receptionist</span>
              </li>
            </ul>
          </div>
        </header>

        <section className="sub-cont__wrap">
          <article>
            <div className="tbl-wrap">
              <DataGrid
                id="gridCont1"
                dataSource={doctor}
                columnHidingEnabled={true}
                noDataText="No data"
                selection={{ mode: "single" }}
                hoverStateEnabled={true}
                className="type-ct"
                style={{ minWidth: 1000 }}
              >
                <LoadPanel enabled />
                <SearchPanel visible={true} highlightCaseSensitive={true} />
                <Column
                  caption="No"
                  width={100}
                  cellRender={(e) => {
                    return e.row.rowIndex + 1;
                  }}
                />
                <Column
                  dataField="name"
                  caption="NAME/ID"
                  cellTemplate={(container: any, options: any) => {
                    $("<a class='link'>")
                      .on("click", () => {
                        history.push("/medicalInfo/" + options.data.doctorSeq);
                      })
                      .append(options.value + " (" + options.data.loginId + ")")
                      .appendTo(container);
                  }}
                />
                <Column
                  dataField="role"
                  caption="ROLE"
                  cellTemplate={(container: any, options: any) => {
                    if (options.value === "D") {
                      $("<span class='badge sm blue-bg'>")
                        .append("Dr")
                        .appendTo(container);
                    } else {
                      $("<span class='badge sm dark-bg'>")
                        .append("Rp")
                        .appendTo(container);
                    }
                  }}
                />
                <Column
                  dataField="linkDevice"
                  caption="Linked PATIENTS"
                  cellTemplate={(container: any, options: any) => {
                    const dpr = _.filter(doctorPatientRelation, {
                      doctorSeq: options.data.doctorSeq,
                    });

                    $("<span class='icon'>")
                      .append($("<i>", { class: "fa fa-user" }))
                      .appendTo(container)
                      .append("<span> " + dpr.length + " </span>")
                      .appendTo(container);
                  }}
                />

                <Column
                  dataField="inputUtcDt"
                  caption="REGISTER DATE"
                  dataType="date"
                  format="yyyy-MM-dd"
                />

                <Paging defaultPageSize={10} />
                <Pager
                  visible={true}
                  allowedPageSizes={[10, 20, "all"]}
                  displayMode={"full"}
                  showPageSizeSelector={true}
                  showInfo={true}
                  showNavigationButtons={true}
                />
              </DataGrid>
            </div>
          </article>
        </section>
      </section>
    </main>
  );
};

export default Medical;
