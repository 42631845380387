import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";
import DataGrid, {
  Column,
  ColumnChooser,
  Editing,
  Pager,
  Paging,
  SearchPanel,
} from "devextreme-react/data-grid";
import "devextreme/dist/css/dx.light.css";

import "../../css/font-awesome.min.css";
import "../../css/layout.css";
import "../../css/custom.css";
import "jquery-ui/themes/base/jquery-ui.css";
import $ from "jquery";
import "jquery-ui";
import { Common } from "../../lib/utils/common";
import { useHistory } from "react-router-dom";
import { DateBox, SelectBox, TextBox } from "devextreme-react";
import * as _ from "lodash";
import { IEducationProps } from "./IEducation";

import moment from "moment";
import { AdminDataProvider } from "../../lib/service/AdminDataProvider";

export const Education: FunctionComponent<IEducationProps> = (props) => {
  const history = useHistory();
  const common = new Common();

  useEffect(() => {
    common.ResizePage();
    const fetchArticles = async () => {};
  }, [props]);

  return (
    <main id="content" className="content">
      <a href="https://www.youtube.com/embed/vYODpk20l4w?autoplay=1&fs=1&rel=0&enablejsapi=1&playerapiid=ytplayer">
        <img src="https://img.youtube.com/vi/vYODpk20l4w/0.jpg" />
      </a>
      <a href="https://www.youtube.com/embed/Vel7EgHY7JY?autoplay=1&fs=1&rel=0&enablejsapi=1&playerapiid=ytplayer">
        <img src="https://img.youtube.com/vi/Vel7EgHY7JY/0.jpg" />
      </a>
      <a href="https://www.youtube.com/embed/MzDCGh-SIb0?autoplay=1&fs=1&rel=0&enablejsapi=1&playerapiid=ytplayer">
        <img src="https://img.youtube.com/vi/MzDCGh-SIb0/0.jpg" />
      </a>
    </main>
  );
};

export default Education;
