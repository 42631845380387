export const en = {
  A: "BARCODE",
  btn_close: "Close",
  btn_create_upper: "CREATE",
  btn_delete_upper: "DELETE",
  btn_download: "Download",
  btn_edit: "Edit",
  btn_id_duplication_check: "ID Duplication Check",
  btn_link: "Link",
  btn_ok: "OK",
  btn_password_change: "Password Change",
  btn_print_upper: "PRINT",
  btn_regist: "Regist",
  btn_register: "Register",
  btn_register_new: "Register New",
  btn_reset: "Reset",
  btn_save: "Save",
  btn_save_changes: "Save changes",
  btn_save_upper: "SAVE",
  btn_search: "Search",
  btn_sign_in: "Sign in",
  btn_unlink: "UNLINK",
  btn_verify: "Verify",
  msg_admin_register:
    "This page is Super Admin's page. The Super Admin can register only Company's administrator.",
  msg_already_linked: "has been already linked another HX-461.",
  msg_already_registed:
    "Has been already registed. Please check Administrator info again.",
  msg_basic_password:
    "The basic password is '1234'. You are required to change the default password.",
  msg_can_use: "can use.",
  msg_cancel: "it has been canceled.",
  msg_changed: "Has been changed.",
  msg_check_duplication: "Please check for Duplication.",
  msg_choose_device_serial: "Choose a HX-461 Device Serial...",
  msg_choose_ms: "Choose a Medical Staff...",
  msg_choose_patients: "Choose a Patients...",
  msg_create_id: "Do you really want create about patient ID ?",
  msg_create_id2: "Please create Patient ID.",
  msg_created: "Has been create.",
  msg_delete_admin: "Do you really want delete about This admin ?",
  msg_delete_ms: "Do you really want Delete Medical Staff?",
  msg_delete_patient: "Do you really want delete patient?",
  msg_deleted: "Has been deleted.",
  msg_edit_ms: "Do you really want edit Medical Staff info?",
  msg_edit_patient: "Do you really want edit patient's info?",
  msg_enter_ms_name: "Enter the Medical Staff Name...",
  msg_enter_name: "Enter the name of caller or callee",
  msg_enter_patient_name: "Enter the Patient Name...",
  msg_enter_quantity: "Enter the quantity",
  msg_enter_search_name: "Enter the Search Name...",
  msg_enter_serial: "Enter the Device Serial",
  msg_error_occurred:
    "An error has occurred on the system. \nPlease contact the system administrator",
  msg_id: "Consider carefully before creating an account ID cannot change.",
  msg_id_length:
    "ID must be at least 8 characters in length and contain at least one letter and one numeric character  except specific letters. ex) !,@,#,$,%,^,&,*,?,_,~",
  msg_id_valid_all:
    "ID must be at least 8 characters in length and contain at least one letter and one numeric character  except specific letters. ex) !,@,#,$,%,^,&,*,?,_,~",
  msg_id_valid_letter_number:
    "ID must be at least 8 characters in length and contain at least one letter and one numeric character  except specific letters. ex) !,@,#,$,%,^,&,*,?,_,~",
  msg_id_valid_sa:
    "ID must be at least 8 characters in length and contain at least one letter and one numeric character  except specific letters. ex) !,@,#,$,%,^,&,*,?,_,~",
  msg_id_valid_special_letter:
    "ID must be at least 8 characters in length and contain at least one letter and one numeric character  except specific letters. ex) !,@,#,$,%,^,&,*,?,_,~",
  msg_incorrect_password:
    "incorrect password. Please check the password again.",
  msg_input_birth_gender: "Please input Patient Birth/Gender.",
  msg_input_company: "Please input your Company.",
  msg_input_id: "Please Input your ID",
  msg_input_ms_id: "Please input Medical Staff ID.",
  msg_input_ms_name: "Please input Medical Staff Name.",
  msg_input_ms_password: "Please input Medical Staff Password.",
  msg_input_ms_password_confirm:
    "Please input Medical Staff Password Confirmed.",
  msg_input_ms_role: "Please input Medical Staff Role.",
  msg_input_new_id: "has been already registed. Please input new ID.",
  msg_input_password: "Please Input your Password",
  msg_input_password_confirm: "Please input Password Confirmed.",
  msg_input_patient_name: "Please input Patient Name.",
  msg_matched: "Has been matched.",
  msg_max_count: "The ID is already in use by the maximum allowed. (up to {0})",
  msg_modified: "Has been modified.",
  msg_no_data: "No Data",
  msg_no_url_format:
    "The value you entered is not valid. Enter full URL(https://www.example.com)",
  msg_notmatch_serial:
    "The serial number is not correct. Please check and try again.",
  msg_only_integer: "Please enter numeric only.",
  msg_password_change:
    "Hi! Super Administrator! Welcome to Visit Hicare Solution Admin Service. You should change a password at the first time. Please change the password.",
  msg_password_changed: "Password has been changed.",
  msg_password_changed_1234: "Password has been changed '1234'.",
  msg_password_length: "Password must be at least 4 characters in length.",
  msg_password_not_match: "Password does not match.  Please check password",
  msg_password_space:
    "Password cannot contain any space. Please check your password.",
  msg_patient_already_registed:
    "Has been already registed. Please check patient info again.",
  msg_patient_reset_password:
    "Do you really want reset about patient password ?",
  msg_pid:
    "This field is to link patient information in this solution and the one in your system (optional).",
  msg_pid_already_registed:
    "PID has been already registed. Please check patient info again.",
  msg_register_admin: "Do you really want register an admin ?",
  msg_registered: "Has been registered.",
  msg_reset_admin_password: "Do you really want Reset about admin's password ?",
  msg_reset_password:
    "Do you really want Reset about Medical Staff's password ?",
  msg_sa_special_character:
    "Super Admin Information cannot contain any Special character.",
  msg_select_device: "Please select Device",
  msg_select_ms: "Please select MedicalStaff.",
  msg_select_patient: "Please select Patient.",
  msg_select_type: "Please select the Type.",
  msg_unlink_device: "Do you really want unlink This Device?",
  msg_unlink_ms: "Do you really want unlink This Medical Staff?",
  msg_unlinked: "Has been unlinked.",
  msg_unmatch_ms: "Do you really want unmatching This Medical Staff?",
  msg_url_not_verified:
    "This URL is not verified.\nPlease, check and try again.",
  msg_verified: "Verified successfully!",
  msg_verify_address: "Please verify your address(URL)",
  txt_accept_time_upper: "ACCEPT TIME",
  txt_activity: "Activity",
  txt_activity_minutes: "Activity Minutes",
  txt_address_url: "Address(URL)",
  txt_address_url_upper: "ADDRESS(URL)",
  txt_admin_register_upper: "ADMINISTRATOR REGISTRATION",
  txt_administrator: "Administrator",
  txt_administrator_list_upper: "ADMINISTRATOR LIST",
  txt_alert_level: "Alert Level",
  txt_assessment: "Assessment",
  txt_auto_timezone_upper: "AUTO TIMEZONE",
  txt_barcode_upper: "BARCODE",
  txt_birth_upper: "BIRTH",
  txt_blood_pressure: "Blood Pressure",
  txt_bmi: "BMI",
  txt_body_composition: "Body Composition",
  txt_body_temperature: "Body Temperature",
  txt_bodyfat: "BodyFat",
  txt_callee_name_upper: "CALLEE NAME",
  txt_caller_name_upper: "CALLER NAME",
  txt_calories: "calories",
  txt_calories_burned: "Calories Burned",
  txt_capnography: "CapnoGraphy",
  txt_change_history: "Change History",
  txt_cholesterol: "Cholesterol",
  txt_cholesterol_cardiochek: "Cholesterol(CardioChek)",
  txt_company_organization: "Company / Organization",
  txt_company_upper: "COMPANY",
  txt_create_id_upper: "CREATE ID",
  txt_date: "Date",
  txt_delete_upper: "DELETE",
  txt_device: "Device",
  txt_device_list: "Device List",
  txt_device_type: "Device Type",
  txt_devices: "Devices",
  txt_devices_upper: "DEVICES",
  txt_diastolic: "Diastolic",
  txt_digital_microscope: "Digital Microscope",
  txt_distance: "Distance",
  txt_doctor: "Doctor",
  txt_download_guide:
    "Enter the serial number you received after purchasing the product and download the app",
  txt_duration_upper: "DURATION",
  txt_ecg: "ECG",
  txt_education: "Education",
  txt_end_time_upper: "END TIME",
  txt_fail: "Fail",
  txt_female: "Female",
  txt_first_name: "First Name",
  txt_gender_upper: "GENDER",
  txt_general_settings: "General Settings",
  txt_glucose: "Glucose",
  txt_hba1c: "HbA1c",
  txt_heart_rate: "Heart Rate",
  txt_higher: "Higher",
  txt_history: "History",
  txt_home: "Home",
  txt_id_duplication_check: "ID Duplication Check",
  txt_id_upper: "ID",
  txt_inr: "INR",
  txt_ketone: "Ketone",
  txt_last_name: "Last Name",
  txt_last_update: "Last Update",
  txt_last_update_upper: "LAST UPDATE",
  txt_level_upper: "LEVEL",
  txt_linked_date_upper: "LINKED DATE",
  txt_linked_device_upper: "LINKED DEVICE",
  txt_linked_doctor_upper: "LINKED DOCTOR",
  txt_linked_info: "Linked Info.",
  txt_linked_patient_upper: "LINKED PATIENT",
  txt_linked_patients_upper: "Linked PATIENTS",
  txt_links: "Links",
  txt_list_register: "List & Register",
  txt_login: "Login",
  txt_logout: "Logout",
  txt_lower: "Lower",
  txt_male: "Male",
  txt_matching_pm: "Matching  ( Patient - Medical Staff )",
  txt_matching_pm_pd:
    "Matching  ( Patient - Medical Staff ) / (Patient - Device)",
  txt_measure_history: "Measurement History",
  txt_measure_type_upper: "MEASURE TYPE",
  txt_measurement_upper: "MEASUREMENT",
  txt_medical_staff: "Medical Staff",
  txt_medical_staff_info: "Medical Staff Info.",
  txt_medical_staff_list: "Medical Staff List",
  txt_medical_staff_registration: "Medical Staff Registration",
  txt_medical_staff_upper: "MEDICAL STAFF",
  txt_mesurement_history: "Measurement History",
  txt_middle_name: "Middle Name",
  txt_min: "min",
  txt_missed: "Missed",
  txt_name_id_upper: "NAME/ID",
  txt_name_upper: "NAME",
  txt_next: "Next",
  txt_no_upper: "NO",
  txt_not_set: "Not Set",
  txt_other: "Other",
  txt_oxygen_level: "Oxygen Level",
  txt_parameters: "Parameters",
  txt_password: "Password",
  txt_password_change: "Password Change",
  txt_password_confirm_upper: "PASSWORD CONFIRMED",
  txt_password_confirmed: "Password confirmed",
  txt_password_setting_upper: "PASSWORD SETTING",
  txt_password_upper: "PASSWORD",
  txt_patient: "Patient",
  txt_patient_device: "Patient - Device",
  txt_patient_info: "Patient Info.",
  txt_patient_list: "Patient List",
  txt_patient_ms: "Patient - Medical Staff",
  txt_patient_name_upper: "PATIENT NAME",
  txt_patient_registration: "Patient Registration",
  txt_patient_specific_alert_thresholds: "Patient Specific Alert Thresholds",
  txt_patients_upper: "PATIENTS",
  txt_pft: "PFT",
  txt_pid_linkid_upper: "PID (LINK ID)",
  txt_prev: "Prev",
  txt_preview: "Preview",
  txt_qrcode_upper: "QRCODE",
  txt_recent_register_company: "Recent Register Company",
  txt_receptionist: "Receptionist",
  txt_register_date_upper: "REGISTER DATE",
  txt_register_patient_device_medi:
    "Please Register Patients, Device and Medical Staff.",
  txt_register_patient_medi: "Please Register Patients and Medical Staff.",
  txt_registered: "Has been registered.",
  txt_request_date_upper: "REQUEST DATE",
  txt_respiration: "Respiration",
  txt_role_upper: "ROLE",
  txt_sec: "sec",
  txt_serial: "S/N",
  txt_service_upper: "SERVICE",
  txt_settings: "Settings",
  txt_skin_temp: "Skin temp",
  txt_sn: "S/N(Phone Number)",
  txt_sn_upper: "S/N",
  txt_spo2: "SpO₂",
  txt_start_time_upper: "START TIME",
  txt_status_upper: "STATUS",
  txt_step_count: "Step Count",
  txt_steps: "Steps",
  txt_stethoscope: "Stethoscope",
  txt_success: "Success",
  txt_systolic: "Systolic",
  txt_tc: "TC",
  txt_temperature: "Temperature",
  txt_test_group: "Test Group",
  txt_test_upper: "TEST",
  txt_thanks: "Thank you for purchasing the product.",
  txt_today_upper: "TODAY",
  txt_total_administrator: "Total Administrator",
  txt_total_upper: "TOTAL",
  txt_type_upper: "TYPE",
  txt_unit: "Unit",
  txt_uric_acid: "Uric Acid",
  txt_urine_spot: "Urine(spot)",
  txt_urine_stick: "Urine(stick)",
  txt_video_call_upper: "VIDEO CALL",
  txt_video_history: "Video Call History",
  txt_videocall: "Videocall",
  txt_videocall_history: "Video Call History",
  txt_videocall_status_upper: "STATUS",
  txt_waist: "Waist",
  txt_web_chart_upper: "WEB CHART",
  txt_webchart: "WebChart",
  txt_webpage: "Webpage",
  txt_webpage_upper: "WEBPAGE",
  txt_weight: "Weight",
};
