/* react */
import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";

import { IDeviceLinkProps } from "./IDeviceLink";
import "jquery-ui";
import { Common } from "../../lib/utils/common";
import { useHistory, useParams } from "react-router-dom";

import {
  Button,
  DataGrid,
  DateBox,
  Popup,
  RadioGroup,
  SelectBox,
  TextArea,
  TextBox,
} from "devextreme-react";
import * as _ from "lodash";
import { AdminDataProvider } from "../../lib/service/AdminDataProvider";
import moment from "moment";
import {
  Column,
  Pager,
  Paging,
  SearchPanel,
  LoadPanel,
} from "devextreme-react/data-grid";
import $ from "jquery";

import sBox from "devextreme/ui/select_box";
import { alert } from "devextreme/ui/dialog";

export const DeviceLink: FunctionComponent<IDeviceLinkProps> = (props) => {
  const history = useHistory();
  const common = new Common();
  const param: any = useParams();
  const [device, setDevice] = useState([] as any);
  const [deviceRelations, setDeviceRelations] = useState([] as any);
  const [patient, setPatient] = useState([] as any);
  /* componentDidMount && componentDidUpdate */
  useEffect(() => {
    common.ResizePage();
    const fetchArticles = async () => {
      const adminService = new AdminDataProvider();
      const main = await adminService.GetMainList();

      setPatient(main.data.patient);
      setDevice(main.data.devices);
      setDeviceRelations(main.data.deviceRelations);
    };

    if (localStorage.getItem("id") === "system") {
      history.push("/superAdmin");
    } else if (localStorage.getItem("jwt") !== null) {
      fetchArticles();
    } else {
      history.push("/auth/login");
    }
  }, [props]);

  const _dev = (e: any, d: any) => {
    const id = document.getElementById(e) as HTMLElement;
    return d.getInstance(id);
  };
  const btnDeleteDeviceRelations = async (d: any) => {
    const adminService = new AdminDataProvider();
    adminService.DeleteDeviceRelations(d).then((e: any) => {
      history.push("/deviceLink");
    });
  };

  const btnSave = async (d: any) => {
    const selPatient = _dev("selPatient", sBox).option("value");
    const selDevice = _dev("selDevice", sBox).option("value");

    const json = {
      insungSeq: selPatient,
      deviceId: selDevice,
    };

    const adminService = new AdminDataProvider();
    adminService.addDeviceRelation(json).then((e: any) => {
      history.push("/deviceLink");
    });
  };

  return (
    <main id="content" className="content">
      <section className="sub-top">
        <header>
          <div className="page-history">
            <ul>
              <li>
                <a href="/main">
                  <i className="fa fa-home" aria-hidden="true"></i>
                  <span className="blind">home</span>
                </a>
              </li>
              <li className="current">
                <a href={window.location.href}>
                  <span>Patient - Device</span>
                </a>
              </li>
            </ul>
          </div>
          <h2>
            <span>Patient - Device</span>
            <span className="num-ea">{deviceRelations.length}</span>
          </h2>
        </header>

        <article className="link-wrap">
          <div className="item">
            <h3>Patient List</h3>
            <SelectBox
              id="selPatient"
              items={patient}
              placeholder="선택"
              displayExpr="name"
              valueExpr="insungSeq"
              className="inp"
            />
          </div>
          <Button
            className="btn md blue"
            id="btnSave"
            text="Link"
            type="success"
            icon="fa fa-link"
            stylingMode="contained"
            // useSubmitBehavior={true}
            onClick={btnSave}
          />
          <div className="item">
            <h3>Device Type</h3>
            <div className="item-type">
              <img
                src="../images/glyphicons/glyphicons_162_hx461.png"
                alt="images info text"
              />
              <span>HX-461</span>
            </div>
            <SelectBox
              id="selDevice"
              items={device}
              placeholder="선택"
              displayExpr="devices_device_id"
              valueExpr="devices_device_id"
              className="inp"
            />
          </div>
        </article>
      </section>

      <section className="sub-cont search-mode">
        <section className="sub-cont__wrap">
          <article>
            <div className="tbl-wrap">
              <DataGrid
                id="gridCont1"
                dataSource={deviceRelations}
                hoverStateEnabled={true}
                className="type-ct"
                style={{ minWidth: 1000 }}
              >
                <LoadPanel enabled />
                <SearchPanel visible={true} highlightCaseSensitive={true} />
                <Column
                  caption="No"
                  width={100}
                  cellRender={(e) => {
                    return e.row.rowIndex + 1;
                  }}
                />
                <Column
                  dataField="name"
                  caption="PATIENT NAME"
                  cellTemplate={(container: any, options: any) => {
                    $("<a  class='link'>")
                      .on("click", () => {
                        history.push("/patientInfo/" + options.data.insungSeq);
                      })
                      .append(options.value)
                      .appendTo(container);
                  }}
                />
                <Column dataField="type" caption="TYPE" />
                <Column dataField="deviceId" caption="S/N(Phone Number)" />
                <Column
                  dataField="inputUtcDt"
                  caption="LINKED DATE"
                  dataType="date"
                  format="yyyy-MM-dd"
                />
                <Column
                  dataField="STATUS"
                  caption="STATUS"
                  cellTemplate={(container: any, options: any) => {
                    $("<span class='link' style='cursor:pointer'>")
                      .on("click", () => {
                        btnDeleteDeviceRelations(options.data.seq);
                      })
                      .append(
                        "<i class='fa fa-times' aria-hidden='true'>UNLINK</i>"
                      )
                      .appendTo(container);
                  }}
                />
                <Paging defaultPageSize={10} />
                <Pager
                  visible={true}
                  allowedPageSizes={[10, 20, "all"]}
                  displayMode={"full"}
                  showPageSizeSelector={true}
                  showInfo={true}
                  showNavigationButtons={true}
                />
              </DataGrid>
            </div>
          </article>
        </section>
      </section>
    </main>
  );
};

export default DeviceLink;
